import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useSelector } from "react-redux";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LinearProggresComponent from "../../components/LinearProggresComponent";
import Moment from "react-moment";
import moment from "moment";
import SurfingOutlinedIcon from "@mui/icons-material/SurfingOutlined";
import { useEffect, useState } from "react";
import SignIn from "../auth/SignInComponent";
import Skeleton from "@mui/material/Skeleton";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { BlinkAnimationComponent } from "../../theme/theme";
import useAnalyticsEventTracker from "../../utils/UseAnalyticsEventTracker";
// import {createCustomClaimsByEmail} from "../../api/db";

const MyAccountPage = () => {
  const gaEventTracker = useAnalyticsEventTracker("MyAccount");
  const emailValid = useSelector((state) => state.emailVerified.value);
  let url = new URL(document.location);
  let params = url.searchParams;
  let cameraNumber = params.get("camera");
  const userInfo = useSelector((state) => state.userInfo.value);
  const userPackage = useSelector((state) => state.userPackage.value);
  const user = useSelector((state) => state.auth.value);
  const proggres = useSelector((state) => state.proggres.value);
  let creationDate = moment().startOf("day");
  let endDate = moment().startOf("day");
  let color = "success";
  let durationDays = 0;
  let currentDay = moment().startOf("day");
  let icon = <CheckCircleOutlineIcon sx={{ mx: 1, color: "green" }} />;

  if (userPackage && userPackage.packageCreationDate) {
    creationDate = new Date(userPackage.packageCreationDate);
  }
  if (userPackage && userPackage.packegeEndsDate) {
    endDate = moment(userPackage.packegeEndsDate);
    durationDays = Math.floor(
      moment.duration(endDate.diff(currentDay)).asDays()
    );
  }
  if (durationDays <= 10) {
    color = "warning";
    icon = <ErrorOutlineIcon color={color} sx={{ mx: 1 }} />;
  }
  if (durationDays <= 7) {
    color = "error";
    icon = (
      <BlinkAnimationComponent sx={{ pt: 1, ml: 1 }}>
        <ErrorOutlineIcon color={color} sx={{ mx: 1 }} />
      </BlinkAnimationComponent>
    );
  }

  return (
    <div>
      {proggres && <LinearProggresComponent />}
      {proggres && (
        <Skeleton
          sx={{ bgcolor: "#ff980000", mt: 2, mx: "auto", borderRadius: "4px" }}
          variant="rectangular"
          width={"40%"}
          height={"63vh"}
          animation="wave"
        />
      )}

      {/* {!proggres && !user && <SignIn />} */}
      {userInfo && user && (
        <Container
          component="main"
          maxWidth="sm"
          sx={{ mb: 4, mt: 2, minHeight: "65vh" }}
        >
          <Card>
            <CardHeader
              title={userInfo.firstName + " " + userInfo.lastName}
              subheader={emailValid ? userInfo.email :
                <div>
                  <Typography
                    sx={{ mr:1 }}
                    component="span"
                    variant="subtitle1"
                    color="red"
                  >
                    {"דרוש אימות"}
                  </Typography>
                  <Typography
                    // sx={{ m: 1, color: "red" }}
                    component="span"
                    variant="subtitle1"
                    color="text.primary"
                  >
                    {"- " +  userInfo.email }
                  </Typography>
                </div>
              }
              titleTypographyProps={{ align: "center" }}
              action={<AccountCircleIcon />}
              subheaderTypographyProps={{
                align: "center",
              }}
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey[200]
                    : theme.palette.grey[700],
                color: (theme) => theme.palette.text.titleHighy,
              }}
            />
            <CardContent sx={{ px: 0, mx: 0, pb: 0 }}>
              <Box
                sx={{
                  direction: "rtl",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  mb: 0,
                  p: 0,
                }}
              >
                <Typography
                  component="h2"
                  variant="subtitle1"
                  color="text.primary"
                >
                  {"My Beach Cam"}
                </Typography>
                {userPackage && <Typography
                  component="h2"
                  variant="h6"
                  color="text.primary"
                >
                  {" מנוי "}{userPackage.packageName ? userPackage.packageName : null}
                </Typography>}
                <Typography
                  component="p"
                  variant="body1"
                  color="text.primary"
                >
                  {"תאריך הרשמה: "}
                  <Moment element="span" format="DD/MM/YYYY">
                    {creationDate}
                  </Moment>
                </Typography>
                <Typography
                  component="p"
                  variant="body1"
                  color="text.primary"
                >
                  {"סטטוס: פעיל עד  "}
                  {userPackage && userPackage.packegeEndsDate && <Moment element="span" format="DD/MM/YYYY" add={{ days: 0 }}>
                    {userPackage.packegeEndsDate}
                  </Moment>}
                </Typography>
                <SurfingOutlinedIcon sx={{ my: 3 }} />

                <Typography
                  component="div"
                  variant="subtitle1"
                  color="text.primary"
                  sx={{
                    mb: 1,
                    backgroundColor: "#eeeeee",
                    px: 2,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    borderRadius: 1,
                    boxShadow: 2,
                  }}
                >
                  {/* <BlinkAnimationComponent sx={{mt:1}}>{icon}</BlinkAnimationComponent> */}
                  {icon}
                  {durationDays + 1 <= 0 ? "המנוי הסתיים" : "נותרו: "}
                  {
                    <Typography
                      sx={{ mx: "2px" }}
                      // variant="subtitle1"
                      color={"text.primary"}
                    >
                      {durationDays + 1 <= 0 ? null : durationDays + 1}
                    </Typography>
                  }
                  {durationDays + 1 <= 0 ? null : " ימים "}
                </Typography>
              </Box>
            </CardContent>
            <CardActions>
              {/* <Button
                onClick={() => gaEventTracker("BuyButtonClick")}
                href="/paymentpage"
                fullWidth
                variant={"contained"}
              >
                {" "}
                לרכישת מנוי{" "}
              </Button> */}
              {/* <Button
                onClick={() => gaEventTracker("BuyTranzilaButtonClick")}
                href="/tranzilapaymentpage"
                fullWidth
                variant={"contained"}
              >
                {" tranzila "}
                לרכישת מנוי{" "}
              </Button> */}
              <Button
                sx={{ p: 1, fontSize: "1rem" }}
                onClick={() => gaEventTracker("BuyTranzilaButton2Click")}
                href="/meshulampaymentpage"
                fullWidth
                variant={"contained"}
              >
                למסלולים ורכישת מנוי{" "}
              </Button>
              {/* <Button
                onClick={() => gaEventTracker("paymetpage3")}
                href="/paymentpage3"
                fullWidth
                variant={"contained"}
              >
                {"פרו שנתי - משולם"}
              </Button> */}
            </CardActions>
          </Card>
        </Container>
      )}
    </div>
  );
};

export default MyAccountPage;
