import React from "react";
import { useEffect, useState } from "react"
import Avatar from "@mui/material/Avatar";
import AppsIcon from "@mui/icons-material/Apps";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { textfieldStyling } from "../../theme/theme.js";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { validateField } from "../../utils/validation.js";
// import Alert from "@mui/material/Alert";

// import { applyActionCode, getAuth } from "firebase/auth";
// import LinearProggresComponent from "../../components/LinearProggresComponent";
import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import { green } from "@mui/material/colors";

const AuthHandleResetPassword = (params) => {
    const [accountEmail, setAccountEmail] = useState(false);
    const [newPassword, setNewPassword] = useState(false);
    const [error, setError] = useState(false);
    const [info, setInfo] = useState(false);
    const { auth, actionCode } = params;
    const [validationError, stValidationError] = useState(false);
    const [passwordValid, setPasswordValid] = useState(false);

    const passwordHamdler = () => {
        const validation = validateField("password", newPassword);
        if (validation.valid) {
            stValidationError(false);
            confirmReset();
            return
        }
        stValidationError(validation.error)
    }

    const confirmReset = () => confirmPasswordReset(auth, actionCode, newPassword).then((resp) => {
        // Password reset has been confirmed and new password updated.
        setInfo("הסיסמה החדשה התקבלה")
    }).catch((error) => {
        setInfo(JSON.stringify(error))
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
        console.log("confirmPasswordReset-error", error.code)
    });
    // Localize the UI to the selected language as determined by the lang
    // parameter.

    // Verify the password reset code is valid.
    useEffect(() => {
        actionCode && verifyPasswordResetCode(auth, actionCode).then((email) => {
            setAccountEmail(email);
        })
            .catch((error) => {
                console.log("verifyPasswordResetCode-error:", error)
                setError("לא ניתן להזין סיסמה חדשה, יש ללחוץ שוב על הקישור במייל או לחזור לאיפוס סיסמה");
                // Invalid or expired action code. Ask user to try to reset the password
                // again.
            });
        // Specify how to clean up after this effect:
        return function cleanup() {
        };
    }, [auth, actionCode]);

    const BasicAlerts = (message) => {
        return (
            <Stack sx={{ width: "100%" }} spacing={2}>
                <Alert severity="error">{error}</Alert>
            </Stack>
        );
    };

    return (
        <div id="AuthHandleResetPassword" className="text-center pad-x-1">
            <Container id="passwordForm" component="main" maxWidth="xs" dir="rtl">
                <CssBaseline />
                {/* <Box
                    p={1}
                    sx={{
                        marginTop: 0,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        background: "default",
                        "& .MuiOutlinedInput-root": {
                            "& > fieldset": {
                                borderColor: "text.border",
                            },
                        },
                    }}
                > */}
                <Typography component="h3" variant="h6" sx={{ mt: 4 }}>
                    סיסמה חדשה
                </Typography>
                {accountEmail && <span>{accountEmail}</span>}
                {info &&
                    <Typography color={green} component="h3" variant="span">
                        {info}
                    </Typography>}
                {info &&
                    <Button
                        onClick={() => window.location = "/signin"}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 1, mb: 2 }}
                    >
                        כניסה
                    </Button>}
                {!error && !info && <Typography variant="subtitle1" gutterBottom component="div">
                    נא להזין סיסמה חדשה
                </Typography>}
                <Box component="div" noValidate sx={{ mt: 1 }}>
                    {error && !info ? <BasicAlerts sx={{ mt: 1, mb: 1 }} /> : null}
                    {error && !info &&

                        <Button
                            onClick={() => window.location = "/reset"}
                            // type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            איפוס סיסמה
                        </Button>}
                    {!error && !info && <TextField
                        variant="standard"
                        sx={{ ...textfieldStyling }}
                        margin="normal"
                        required
                        fullWidth
                        id="newPassword"
                        label="הזנת סיסמה חדשה"
                        name="newPassword"
                        autoComplete="password"
                        onChange={(e) => setNewPassword(e.target.value)}
                    />}
                    {validationError && <Alert severity="error">{validationError}</Alert>}
                    {!error && !info &&
                        <Button
                            onClick={passwordHamdler}
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            המשך
                        </Button>}
                </Box>
                {/* </Box> */}
                <div className="flex-justify-center">
                    <Avatar sx={{ mt: 1, ml: 2, bgcolor: "inherit", width: '60px', height: "60px" }}>
                        <img
                            src="https://beachcam.co.il/images/BeachCamIcon.png"
                            alt="BeachCam Israel"
                            width="50px"
                        />
                    </Avatar>
                </div>
                <Typography component="h1" variant="h5" sx={{ pb: 2, color: (theme) => theme.palette.text.titleHighy, fontSize: { xs: 22, md: 30 } }}>
                    My Beach Cam
                </Typography>
            </Container>

        </div>
    );
}
export default AuthHandleResetPassword;