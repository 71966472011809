import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Typography from "@mui/material/Typography";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import { CardActionArea } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import SellIcon from "@mui/icons-material/Sell";
import { membershipPackages } from "../data/packages";

export default function SelectPackageComponent(props) {
  const { select } = props;
  let url = new URL(document.location);
  let params = url.searchParams;
  let cameraNumber = params.get("camera");
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />

      <Container
        className="SelectPackageComponent"
        disableGutters
        maxWidth="sm"
        component="main"
        sx={{ pt: 0, pb: 1, px: 2 }}
      >
        {/* <div className="flex-justify-center">
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <SellIcon />
          </Avatar>
        </div> */}
        <Typography
          variant="h5"
          align="center"
          color="text.bottle"
          component="p"
        >
          משתמש רשום
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="text.primary"
          component="p"
        >
          עליך לבחור את המסלול המתאים
        </Typography>
      </Container>

      <Container className="membershipPackages" maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end" sx={{mb:8}}>
          {membershipPackages.map((element) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={element.mbcCode}
              xs={12}
              sm={element.mbcCode === "pro1" ? 12 : 6}
              md={4}
            >
              <Card id={element.mbcCode + "-card"} className={element.mbcCode + "-card"}>
                <CardActionArea
                  onClick={() => select(element)}
                >
                  <CardHeader
                    title={element.title}
                    subheader={element.teaser}
                    titleTypographyProps={{ align: "center" }}
                    action={
                      element.mbcCode === "pro12" ? (
                        <StarIcon />
                      ) : null
                    }
                    subheaderTypographyProps={{
                      align: "center",
                    }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                      color: (theme) => theme.palette.text.titleHighy,
                    }}
                  />
                  <CardContent>
                    <Typography
                      dir="rtl"
                      component="p"
                      variant="subtitle1"
                      align="center"
                    >
                      {' במקום '}
                      {element.listPrice}
                      {' ש"ח '}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        mb: 2,
                      }}
                    >
                      <Typography dir="rtl"
                        component="h2"
                        variant="h5"
                        color="text.primary"
                      >
                        {' רק '}
                        {element.totalPrice}
                        {' ש"ח '}

                      </Typography>
                    </Box>

                    <Typography
                      dir="rtl"
                      component="p"
                      variant="subtitle1"
                      align="center"
                    >
                      {element.discountText}
                    </Typography>
                    <Typography
                      dir="rtl"
                      color="#1b6470"
                      component="li"
                      variant="subtitle1"
                      align="center"
                    >
                      {element.info}
                    </Typography>
                  </CardContent>
                  {/* <CardActions>
                    <Button fullWidth variant="contained">
                      {'לרכישה'}
                    </Button>
                  </CardActions> */}
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </React.Fragment>
  );
};
