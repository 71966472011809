import { styled } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { purple, green } from "@mui/material/colors";

export const themeLight = createTheme({
  palette: {
    // primary: purple,
    // secondary: green,
    dir: "rtl",
    background: {
      default: "#fff",
    },
    background2: {
      default: "#a5a5a50d",
    },
    text: {
      border: "#0000003b",
      titleHighy: "#1b6470",
      blue: "#1976d2",
      bottle: "#1976d2",
      orange: "#d21919"
    },
  },
});

export const themeDark = createTheme({
  palette: {
    background: {
      default: "#2c2e37",
    },
    background2: {
      default: "#363945",
    },
    text: {
      primary: "#ff9800",
      secondary: "#ff9800",
      border: "#ff9800",
      blue: "#1976d2",
      bottle: "#2ab1c7",
      orange: "#d21919"
    },
  },
});

export const BlinkAnimationComponent = styled("div")({
  "@keyframes pulsate": {
    from: {
      opacity: 1,
      transform: "scale(1)",
    },
    to: {
      opacity: 0,
      transform: "scale(3)",
    },
  },
  animation: "pulsate 2.3s infinite ease",
});

export const textfieldStyling = {
  pt: 0,
  "& label": {
    right: ".3em",
    transformOrigin: "top right",
    "&.Mui-focused": {
      right: ".3em",
      // left: "calc(70% - 1rem)",
    },
  },
  // "&.Mui-filled": {
  //   right: "auto",
  //   left: 0,
  // },
};

export const linkCss = {
  my: 1,
  mx: 1.5,
  "&[disabled]": {
    cursor: "default",
    "&:hover": {
      textDecoration: "none",
    },
  },
};
