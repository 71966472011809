import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import SellIcon from "@mui/icons-material/Sell";
import Typography from "@mui/material/Typography";
import InputNumberComponent from "./InputNumberComponent";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useState } from "react";

export default function PhonePaymentComponent(props) {
    const { setPhoneNumber } = props;
    const [inputVal, setInputVal] = useState(false);
    const [checked, setChecked] = useState(false);

    const validatePhoneNumberIsrael = (PHONE) => {
        const regex = new RegExp('/^0?(([23489]{1}\d{7})|[5]{1}\d{8})$/');
        return !regex.test(PHONE);
    };

    const handleNext = (PHONE) => {
        setPhoneNumber(PHONE);
    }

    const handleChange = (e) => {
        e.preventDefault();
        setChecked(e.target.checked);
    }


    return (
        <Box
            className="PhonePaymentComponent-container"
            sx={{
                width: "370px",
                pt: { xs: 0, ms: 0, md: 2 },
                px: { ms: 0, md: 2 },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >

            <Container
                maxWidth="sm"
                component="main"
                sx={{ pt: 2, pb: 1, px: 2 }}
            >
                <Typography
                    variant="h6"
                    align="center"
                    color="#1976d2"
                    component="h6"
                >
                    יש להזין מספר טלפון
                </Typography>
            </Container>
            <Container sx={{ mx: 2, width: "90%" }}>
                <Box component="form">
                    <TextField
                        sx={{ width: "100%" }}
                        variant="outlined"
                        label="מספר טלפון"
                        type="tel"
                        onChange={(e) => setInputVal(e.target.value)}
                        name="טלפון"
                        size="small"
                        placeholder="טלפון - של המשלם"
                        focused
                    />
                    <Box>


                        <FormControlLabel sx={{ margin: " 1em auto", }}
                            control={<Checkbox
                                checked={checked}
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                                label="Required"
                                value="remember"
                                color="primary"
                            />}
                            labelPlacement="start"
                            label={<Typography
                                variant="body2"
                                align="center"
                                color="#1976d2"
                                component="h6"
                            >
                                קראתי ומאשר תנאי שימוש,
                                מאשר שמירת כרטיס לחיוב עתידי
                            </Typography>}
                        />
                        <Typography
                                variant="body2"
                                align="center"
                                color="#363945"
                                component="p"
                            >
                                בתשלום בארנקים דיגיטלים לא ישמר כרטיס לחיוב עתידי
                            </Typography>
                    </Box>

                </Box>
                <Box
                    // disableGutters
                    maxWidth="sm"
                    component="main"
                    sx={{ pt: 2, pb: 1, px: 2 }}
                >

                    {/* <FormControlLabel sx={{ direction: "rtl" }}
                    control={<Checkbox value="remember" color="primary"  />}
                    label={<Typography
                        variant="p"
                        align="center"
                        color="#1976d2"
                        component="h6"
                    >
                        קראתי ומאשר תקנון,
                        מאשר שמירת כרטיס לחיוב עתידי
                    </Typography>}
                /> */}
                </Box>
                {checked && inputVal.length > 7 && <Button
                    id="select-Bit-btn-Bit"
                    onClick={() => handleNext(inputVal)}
                    fullWidth
                    variant="contained"
                    sx={{
                        height: "42px",
                        p: 0,
                        my: 2,
                        // backgroundImage: `url(${bitImageUrl})`,
                        backgroundSize: "50px",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        // backgroundColor: "#69b5c1"
                    }}
                >
                    המשך
                </Button>}
            </Container>
        </Box>
    );
}
