import Box from "@mui/material/Box";
import { useState } from "react";
import LinearProggresComponent from "./LinearProggresComponent";


export default function PaymentIfarameComponent(props) {
  const { iframeDidLoad, urlData, method } = props;
  const [load, setLoad] = useState(true);
  const iframeLoaded = (event) => {
    event.preventDefault();
    setLoad(false);
    iframeDidLoad(event);
    if (method === "Bit") {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      return
    }
    if(window.innerWidth <= 600 )
    window.scrollTo({
      top: 300,
      left: 0,
      behavior: "smooth",
    })
  }

  return (
    <Box className="payment-Box" height="100%" width="96%" sx={{ pt: { ms: 0, md: 0 }, px: { ms: 0, md: 2 }, display: "flex", flexDirection: "column", justifyContent: "center" }}>
      {load && <LinearProggresComponent />}
      <iframe
        allow="payment"
        className={(method === "Bit") ? "tranzila1-iframe meshulam-iframe bit_iframe" : "tranzila1-iframe meshulam-iframe credit-iframe"}
        onLoad={(e) => iframeLoaded(e)}
        title="my beach cam payment page"
        id="meshulam_iframe"
        src={`${urlData.url}&processId=${urlData.processId}&processToken=${urlData.processToken}${method === "GooglePay" && "&payment=allow"} `}
        // width="1200"
        // height="625px"
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </Box>
  )
}