import React from "react";
import SignIn from "../../pages/auth/SignInComponent";
import Container from "@mui/material/Container";
import AlbumComponent from "../../components/AlbumComponent";
import Stack from "@mui/material/Stack";
import { useState, useEffect } from "react";
import CamListComponent from "../../components/CamListComponent";
import { cameraList } from "../../data/cameralist";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { IconButton } from "@mui/material";
import PlaylistPlayOutlinedIcon from "@mui/icons-material/PlaylistPlayOutlined";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import MyAccountPage from "../myaccount/MyAccountPage";
import MessageComponent from "../../components/MessageComponent";
import { Camera } from "@mui/icons-material";
import IframeComponent from "../../components/IframeComponent";
import ForecastComponent from "../../components/ForcastComponent";
import Typography from "@mui/material/Typography";

const CamerasPage = () => {
  const userPackage = useSelector((state) => state.userPackage.value);
  const user = useSelector((state) => state.auth.value);
  let currentDay = moment().startOf("day");
  let [searchParams, setSearchParams] = useSearchParams();
  let url = new URL(document.location);
  let params = url.searchParams;
  let cameraNumber = params.get("camera");

  let camera = cameraList.find((obj) => {
    return obj.num === Number(cameraNumber);
  });

  let durationDays = 0;
  let endDate = "";

  const [cam, setCam] = useState(camera ? camera : cameraList[1]);
  const [viwe, setViwe] = useState(
    cameraNumber && cameraNumber !== "null" ? "cam" : "album"
  );
  if (userPackage && userPackage.packegeEndsDate) {
    endDate = moment(userPackage.packegeEndsDate);
    durationDays = Math.floor(
      moment.duration(endDate.diff(currentDay)).asDays()
    );
  }
  // useEffect(() => {
  //   if (camera && camera.num && camera.num !== cam.num) {
  //     setCam(camera ? camera : cameraList[1]);
  //   }
  //   return () => { };
  // }, [searchParams, camera, cam.num]);

  const isUserPackageDateValid = (packageEndsDate) => {
    if (durationDays + 1 <= 0) {
      return false;
    }
    return true;
  }

  const GetPackageNumberDaysLeftMessage = (numberDays) => {
    if (durationDays + 1 <= numberDays) {
      return "הודעה: המנוי עומד להסתיים בקרוב - פרטים כאן";
    }
    return "";
  }

  const albumButtoHandler = () => {
    viwe === "album" ? setViwe("cam") : setViwe("album");
  };
  const listButtoHandler = () => {
    viwe === "list" ? setViwe("cam") : setViwe("list");
  };
  const cameraSelected = (camera) => {
    console.log("camera:", camera);
    // if (camera && camera.num && camera.num !== cam.num) {
    //   setCam(camera ? camera : cameraList[1]);
    // }
    // setSearchParams({camera:camera.num});
    setViwe("cam");
    window.location = "/cameras?camera=" + camera.num;
    // setCam(camera? camera: cameraList[Camera.num]);
  };
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  return (
    <Container
      className="cameras-page"
      disableGutters
      maxWidth="md"
      component="main"
      sx={{ pb: 2, px: 1 }}
    >
      {userPackage &&
        user &&
        isUserPackageDateValid(userPackage.packageEndsDate) &&
        <MessageComponent message={GetPackageNumberDaysLeftMessage(3)} />}
      <div className="signin-container">
        <div>
          <Stack
            sx={{
              fontSize: "48px",
              pt: 0,
              px: 1,
              position: "absolute",
              top: 7,
              borderRadius: 2,
              left: {
                xs: "23%",
                sm: "30%",
                md: "55%",
                lg: "42%",
              },
            }}
            direction="row"
            spacing={2}
            justifyContent="center"
          >
            <IconButton
              sx={{
                fontSize: "large",
                bgcolor: "#f5f5dc",
                "&:hover": {
                  background: "#f8e6cb",
                },
              }}
              color="warning"
              component="span"
              onClick={() => albumButtoHandler()}
            >
              {viwe === "album" ? (
                <PhotoCamera sx={{ fontSize: 32, color: "warning" }} />
              ) : (
                <DynamicFeedIcon sx={{ fontSize: 32, color: "#1b6470" }} />
              )}{" "}
              {/* {viwe === "album" ? " מצלמה " : " אלבום "}{" "} */}
            </IconButton>
            <IconButton
              sx={{
                fontSize: "large",
                bgcolor: "#f5f5dc",
                "&:hover": {
                  background: "#f8e6cb",
                },
              }}
              color="warning"
              component="span"
              onClick={() => listButtoHandler()}
            >
              {viwe === "list" ? (
                <PhotoCamera sx={{ fontSize: 32, color: "warning" }} />
              ) : (
                <PlaylistPlayOutlinedIcon
                  sx={{ fontSize: 32, color: "#1b6470" }}
                />
              )}{" "}
              {/* {viwe === "list" ? " מצלמה" : "  רשימה"}{" "} */}
            </IconButton>
          </Stack>
          <div className="album-container">
            {viwe === "album" ? (
              <AlbumComponent
                cameraList={cameraList}
                selectCam={(cam) => cameraSelected(cam)}
                cam={cam}
              />
            ) : null}
          </div>
          {viwe === "list" ? (
            <div className="camList-container animate-box-in-y">
              <CamListComponent
                cameraList={cameraList}
                selectCam={(cam) => cameraSelected(cam)}
                cam={cam}
              />
            </div>
          ) : null}
          {cam && userPackage && isUserPackageDateValid(userPackage.packegeEndsDate) ? (
            <Stack
              sx={{
                display: viwe === "cam" ? "block" : "none",
                pt: 2,
                mt: 0,
              }}
            >
              <div className="cmera-containe">
                <div className="text-center">
                  <Typography component="h3" variant="h3" sx={{ mt: 0,  color: (theme) => theme.palette.text.titleHighy, fontSize: { xs: 24, md: 24 } }}>
                    {cam.name}
                  </Typography>
                </div>
                <IframeComponent camSrc={cam.playerSrc} />
                {/* <ForecastComponent camForecastCode={cam.code} /> */}
              </div>
            </Stack>
          ) : !isUserPackageDateValid() && viwe === "cam" && <MyAccountPage />}
        </div>
      </div>
    </Container>
  );
};

export default CamerasPage;
