import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slice/authSlice";
import emailVerificationReducer from "./slice/emailVerification";
import deviceIdReducer from "./slice/deviceIdSlice";
import cameraReducer from  "./slice/cmeraSlice";
import userInfoReducer from "./slice/userInfoSlice";
import userPackageReducer from "./slice/userPackageSlice";
import proggresReducer from "./slice/proggresSlice";
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
const persistConfig = {
  key: 'root',
  storage,
};
const reducers = combineReducers({
  auth: authReducer,
  emailVerified: emailVerificationReducer,
  deviceId: deviceIdReducer,
  camera: cameraReducer,
  userInfo: userInfoReducer,
  userPackage: userPackageReducer,
  proggres: proggresReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);
export  const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
          serializableCheck: {
              ignoredActions: [FLUSH, PAUSE, PERSIST, PURGE, REGISTER],
          },
      }),
});
// export const store = configureStore({
//   reducer: {
//     auth: authReducer,
//     userInfo: userInfoReducer,
//     proggres: proggresReducer,
//   },
// });