export const cameraList = [
  {
    subText: "האקדמיה לגלישה סלינה תל אביב",
    code: "yafo",
    num: 8,
    name: "יפו האקדמיה לגלישה",
    snapShotUrl:
      "https://ipcamlive.com/player/snapshot.php?alias=664cd75b7639c",
      playerSrc: "https://g0.ipcamlive.com/player/player.php?alias=664cd75b7639c&autoplay=1&skin=white&hidelink=true&%20%20%20%20%20%20%20%20%20%20disablereportbutton=true&disableautofullscreen=1&disablezoombutton=1&disableframecapture=1&disableuserpause=1",
  },
  // {
  //   subText: "רצועת החוף לאורך הטיילת",
  //   code: "tel-aviv",
  //   num: 9,
  //   name: "טיילת תל אביב",
  //   snapShotUrl:
  //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_FOc5oEe8KpMM0wlpY0h83POaHpFhcV6hHA&usqp=CAU",
  //     playerSrc: "https://gocamstream.com/WebRTCApp/play.html?name=0003",
  // },
  {
    subText: "הביץ` קלאב חוף אביב",
    code: "dolphinarium",
    num: 5,
    name: "דולפינריום תל אביב",
    snapShotUrl:
      "https://ipcamlive.com/player/snapshot.php?alias=5f6364b5551d7",
      playerSrc: "https://g0.ipcamlive.com/player/player.php?alias=5f6364b5551d7&autoplay=1&skin=white&hidelink=true&%20%20%20%20%20%20%20%20%20%20disablereportbutton=true&disableautofullscreen=1&disablezoombutton=1&disableframecapture=1&disableuserpause=1",
  },
  // {
  //   subText: "צופה לחוף גורדון",
  //   code: "topsea",
  //   num: 15,
  //   name: "גורדון תל אביב",
  //   snapShotUrl:
  //     "https://images1.ynet.co.il/PicServer5/2019/07/25/9385272/tap.jpg",
  //     playerSrc: "https://gocamstream.com/WebRTCApp/play.html?name=0005",
  // },
  {
    subText: "בחסות ימית סחר",
    code: "hilton",
    num: 3,
    name: "הילטון תל אביב",
    snapShotUrl:
      "https://ipcamlive.com/player/snapshot.php?alias=63454584c4c3c",
      playerSrc: "https://g0.ipcamlive.com/player/player.php?alias=63454584c4c3c&autoplay=1&skin=white&hidelink=true&%20%20%20%20%20%20%20%20%20%20disablereportbutton=true&disableautofullscreen=1&disablezoombutton=1&disableframecapture=1&disableuserpause=1",
  },
  {
    subText: "חוף הכוכבים הרצליה",
    code: "herzliya-marina-dromi",
    num: 1,
    name: "הדרומי הרצליה",
    snapShotUrl:
      "https://ipcamlive.com/player/snapshot.php?alias=621521fe036e6",
      playerSrc: "https://g0.ipcamlive.com/player/player.php?alias=621521fe036e6&autoplay=1&skin=white&hidelink=true&%20%20%20%20%20%20%20%20%20%20disablereportbutton=true&disableautofullscreen=1&disablezoombutton=1&disableframecapture=1&disableuserpause=1",
  },
  {
    subText: "הטוב והעמוס ביותר בארץ",
    code: "herzliya-marina-dromi",
    num: 13,
    name: "מרינה דרום הרצליה",
    snapShotUrl:
      "https://ipcamlive.com/player/snapshot.php?alias=647359b22f88d",
      playerSrc: "https://ipcamlive.com/player/player.php?alias=647359b22f88d&autoplay=1&skin=white&hidelink=true&%20%20%20%20%20%20%20%20%20%20disablereportbutton=true&disableautofullscreen=1&disablezoombutton=1&disableframecapture=1&disableuserpause=1",
  },
  {
    subText: "ארנה פויינט המקום לסערות החורף", 
    code: "herzliya-marina",
    num: 2,
    name: "מרינה צפון הרצליה",
    snapShotUrl:
      "https://ipcamlive.com/player/snapshot.php?alias=63454a0f442fc",
      playerSrc: "https://g0.ipcamlive.com/player/player.php?alias=63454a0f442fc&autoplay=1&skin=white&hidelink=true&%20%20%20%20%20%20%20%20%20%20disablereportbutton=true&disableautofullscreen=1&disablezoombutton=1&disableframecapture=1&disableuserpause=1",
  },
  {
    subText: "הפגזים של זבולון למתקדמים",
    code: "herzliya-zvulun",
    num: 4,
    name: "זבולון הרצליה",
    snapShotUrl:
      "https://ipcamlive.com/player/snapshot.php?alias=634548590c353",
      playerSrc: "https://g0.ipcamlive.com/player/player.php?alias=634548590c353&autoplay=1&skin=white&hidelink=true&%20%20%20%20%20%20%20%20%20%20disablereportbutton=true&disableautofullscreen=1&disablezoombutton=1&disableframecapture=1&disableuserpause=1",
  },
  {
    subText: "שוואטינה לגולשי הרוח",
    code: "haifa",
    num: 6,
    name: "בת גלים חיפה",
    snapShotUrl:
      "https://ipcamlive.com/player/snapshot.php?alias=60997a5d22ca9",
      playerSrc: "https://ipcamlive.com/player/player.php?alias=60997a5d22ca9&autoplay=1&skin=white&hidelink=true&%20%20%20%20%20%20%20%20%20%20disablereportbutton=true&disableautofullscreen=1&disablezoombutton=1&disableframecapture=1&disableuserpause=1",
  },
  {
    subText: "הקזינו חיפה",
    code: "haifa-backdoor",
    num: 62,
    name: " ענק הביטוח בקדור חיפה",
    snapShotUrl:
      "https://ipcamlive.com/player/snapshot.php?alias=5ffd9eb29b665",
      playerSrc: "https://ipcamlive.com/player/player.php?alias=5ffd9eb29b665&autoplay=1&skin=white&hidelink=true&%20%20%20%20%20%20%20%20%20%20disablereportbutton=true&disableautofullscreen=1&disablezoombutton=1&disableframecapture=1&disableuserpause=1",
  },
  {
    subText: "מועדון הגלישה המוביל בצפון",
    code: "haifa-peak",
    num: 11,
    name: "סרף קלאב חיפה",
    snapShotUrl:
      "https://ipcamlive.com/player/snapshot.php?alias=60acaa1aeee83&autoplay=1&skin=white&hidelink=true&disablereportbutton=true&disableautofullscreen=1",
      playerSrc: "https://ipcamlive.com/player/player.php?alias=60acaa1aeee83&autoplay=1&skin=white&hidelink=true&disablereportbutton=true&disableautofullscreen=1&disablezoombutton=1&disableframecapture=1&disableuserpause=1",
  },
  {
    subText: "לזכרו של נאור קאלו",
    code: "maagan-michael",
    name: "מעגן מיכאל",
    num: 10,
    snapShotUrl:
      "https://ipcamlive.com/player/snapshot.php?alias=5b17c8292282c&autoplay=1&skin=white&hidelink=true&disablereportbutton=true&disableautofullscreen=1",
      playerSrc: "https://ipcamlive.com/player/player.php?alias=5b17c8292282c&autoplay=1&skin=white&hidelink=true&%20%20%20%20%20%20%20%20%20%20disablereportbutton=true&disableautofullscreen=1&disablezoombutton=1&disableframecapture=1&disableuserpause=1",
  },
  {
    subText: 'רישיון לסירות מנוע ואופנועי ים',
    code: "haifa-peak",
    name: "מרידיאן חיפה סיסקול",
    num: 12,
    snapShotUrl:
      "https://ipcamlive.com/player/snapshot.php?alias=633ef1ad3453e",
      playerSrc: "https://ipcamlive.com/player/player.php?alias=633ef1ad3453e&autoplay=1&skin=white&hidelink=true&disablereportbutton=true&disableautofullscreen=1&disablezoombutton=1&disableframecapture=1&disableuserpause=1",
  },
  {
    subText: 'חנות הגלישה של הצפון',
    code: "haifa-peak",
    name: "הקריות מג'יק בורדס",
    num: 112,
    snapShotUrl:
      "https://ipcamlive.com/player/snapshot.php?alias=6469b4554f45e",
      playerSrc: "https://ipcamlive.com/player/player.php?alias=6469b4554f45e&autoplay=1&skin=white&hidelink=true&disablereportbutton=true&disableautofullscreen=1&disablezoombutton=1&disableframecapture=1&disableuserpause=1",
  },
];