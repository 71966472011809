import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

export default function MessageComponent(par) {
    const goTo = ()=> window.location="/myaccount";
    const printText = par.message;
    if(printText) {
        return (
            <Box sx={{ width: '100%',textAlign: "right", pr: "0.4em", cursor: "pointer"}} onClick={goTo}>
                <React.Fragment>
                <Button
                variant="outlined"
                sx={{ my: 1, mx: 1.5 }}
                onClick={goTo}
                >
                {printText}
                </Button>
            </React.Fragment>
            </Box>
        );
    }
    return null;
}