import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value:{
    packageCode: "trailer",
  packageCreationDate: "2023-04-16T20:27:30Z",
  packageName: "מנוי פרו לנסיון",
  packegeEndsDate: "2023-04-20T20:27:30.000Z",
  updatedBy: "default",
  }
};

export const userPackageSlice = createSlice({
  name: "userPackage",
  initialState,
  reducers: {
    saveUserPackage: (state, action) => {
      state.value = action.payload;
    },
    updateUserPackage: (state, action) => {
      const newupdateState = {...state.value, ...action.payload.package};
      state.value = newupdateState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { saveUserPackage, updateUserPackage } = userPackageSlice.actions;

export default userPackageSlice.reducer;