import React from "react";
import ProductComponent from "../../components/ProductComponent";

const ProductPage = () => {
  return (
    <div className="text-center">
       <ProductComponent/>
    </div>
  );
};

export default ProductPage;
