import React from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect } from "react"
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";


const PaymentFailPage = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  console.log("window.location.search", window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries());
  const url = new URL(document.location);
  console.log("url-pathname:", url.pathname)
  console.log("params:", params);
  const parsedParams = Object.keys(params)[0];
  const parsedUrlSearchParams = new URLSearchParams(parsedParams);
  const resParams = Object.fromEntries(parsedUrlSearchParams.entries());
  console.log("resParams:", resParams);

  const removeIframe = () => {
    // window.parent.location.reload();
    window.top.postMessage({action: "close"}, "*");
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  })

  return (
    <div id="fail_url_address" className="fail-page">
      <Container id="fail-page-container" component="main" maxWidth="xs" dir="rtl">
        <Box
          p={1}
          sx={{
            padding: "40px 5px",
            boxShadow: 2,
            textAlign: "center",
            marginTop: 12,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background: "default",
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: "text.border",
              },
            },
          }}
        >
          <Typography component="p" variant="body2" sx={{mb: 2, color: (theme) => theme.palette.text.titleHighy, fontSize: { xs: 17, md: 20 } }}>
            לא בוצע תשלום
          </Typography>
          <Typography component="p" variant="body2" sx={{ color: (theme) => theme.palette.text.titleHighy, fontSize: { xs: 17, md: 20 } }}>

          </Typography>
          <Typography component="h1" variant="h6" sx={{ color: (theme) => theme.palette.text.titleHighy, fontSize: { xs: 22, md: 30 } }}>
            My Beach Cam
          </Typography>
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <img
              src="https://beachcam.co.il/images/BeachCamIcon.png"
              alt="BeachCam Israel"
              width="36px"
            />
          </Avatar>
          <Typography sx={{}} variant="body2" gutterBottom component="p">
            התשלום לא הצליח או לא אושר
          </Typography>
          <Typography variant="body2" gutterBottom component="p">
            יש לחזור לאמצעי תשלום 
          </Typography>
          <Box component="div" >
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => removeIframe()}
            >
               חזרה לאמצעי תשלום
            </Button>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default PaymentFailPage;