import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import PlayCircleOutlineSharpIcon from "@mui/icons-material/PlayCircleOutlineSharp";

export default function CamListComponent(props) {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const selectCam = props.selectCam;
  const cam = props.cam;
  const cameraList = props.cameraList;

  const handleListItemClick = (event, camera) => {
    event.preventDefault();
    if(camera === "videoForcast") {
      window.location = "/videoForcast";
      return
    }
    console.log(camera);
    setSelectedIndex(camera.num);
    selectCam(camera);
  };

  return (
    <Box className="CamListComponent"
      sx={{ width: "100%", bgcolor: "background.default", marginTop: "1rem",pt:2 }}
    >
      <List component="nav" aria-label="main mailbox folders">
      {/* <ListItemButton
            key="report"
            selected={cam.num === "videoForcast"}
            onClick={(event) => handleListItemClick(event, "videoForcast")}
          >
            <ListItemText
              sx={{ textAlign: "right", px: 3 }}
              primary="דווח מהחוף"
              secondary={"במהלך היום - עידכון מצב"}
            />
            <ListItemIcon>
              <PlayCircleOutlineSharpIcon color="warning" fontSize="large" />
            </ListItemIcon>
          </ListItemButton> */}
        {cameraList.map((camera) => (
          <ListItemButton
            key={camera.name}
            selected={cam.num === camera.num}
            onClick={(event) => handleListItemClick(event, camera)}
          >
            <ListItemText
              sx={{ textAlign: "right", px: 3 }}
              primary={camera.name}
              secondary={camera && camera.subText && camera.subText}
            />
            <ListItemIcon>
              <PlayCircleOutlineSharpIcon color="warning" fontSize="large" />
            </ListItemIcon>
          </ListItemButton>
        ))}
      </List>
      <Divider />
    </Box>
  );
}
