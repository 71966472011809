import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { textfieldStyling } from "../theme/theme";

export default function InputNumberComponent(props) {
    const focusUsernameInputField = input => {
        if (input) {
            console.log("setTimeout:", input)
          setTimeout(() => {input.focus()}, 100);
        }
      };
    const {label, setSelectedNum} = props; 
    console.log("label:", label)
    const [num, setNum] = React.useState();

    const handleChange = (e) => {
        const regex = /^[0-9\b]+$/;
        console.log("e.target.value:", e.target.value)
        if (e.target.value === "" || regex.test(e.target.value)) {
            setNum(e.target.value);
            setSelectedNum(e.target.value);

            
        }
    };

    return (
        <Box component="form">
            <TextField
                sx={{ }}
                variant="outlined"
                label={label? "  הזנת מספר " + label + "  להוספה ": ""}
                type="number"
                onChange={(e) => handleChange(e)}
                name={label?  label: "noName"}
                size="small"
                placeholder="מספר"
            />
        </Box>
    );
}
