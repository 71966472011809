import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const ProductInfoComponent = (product) => {
    return (
        <Box className="ProductInfoComponent" height="100%" sx={{
            order: { xs: 1, sm: 1, md: 2 },
            maxWidth: 370, px: 2, display: "flex", flexDirection: "column",
            pt: { xs: 0, md: 5 },
            alignItems: { xs: "center", ms: "center", md: "flex-end" },
            textAlign: { xs: "center", ms: "center", md: "right" },
            justifyContent: "center"
        }}>
            <React.Fragment>
                <Typography variant="h5" gutterBottom sx={{backgroundColor: "azure", mb: 0,fontSize: { xs: 25, sm: 25, md: 30 }, color: (theme) => theme.palette.text.titleHighy }}>
                    <img width="40px" src={product.imgSrc} alt={product.title} />
                    {product.title}
                </Typography>
                <Typography variant="h6" sx={{ fontSize: { xs: 18, sm: 18, md: 22 }, mt: 0 }}>
                    {product.teaser}
                </Typography>
                <Typography variant="h6" gutterBottom sx={{ mb: 0, fontSize: { xs: 18, sm: 18, md: 22 }, direction: "rtl" }}>
                    {product.info}
                </Typography>
                <Typography variant="subtitle1" sx={{ mb: 0 }}>
                    {'מחיר רגיל ' + product.listPrice + ' ש"ח '}
                </Typography>
                <Typography variant="subtitle1" sx={{ m: 0 }}>
                    {product.discountText}
                </Typography>
                <Typography variant="h3" sx={{ m: 0, fontSize: { xs: 20, sm: 20, md: 22 }, color: (theme) => theme.palette.text.titleHighy, mt: 2 }}>
                    {'סה"כ ' + product.totalPrice + ' ש"ח '}
                </Typography>
                {/* <Typography variant="h5">
                    {product.teaser}
                    </Typography> */}
            </React.Fragment>
        </Box>
    )
}

export default ProductInfoComponent;
