import React, { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { textfieldStyling } from "../../theme/theme.js";
import { validateField, getMessageText } from "../../utils/validation.js";
import LinearProggresComponent from "../../components/LinearProggresComponent.js";

const theme = createTheme();

const Reset = () => {
  const auth = getAuth();
  const [email, setEmail] = useState("");
  const [alertMessage, setAlertMessage] = useState(false);
  const [success, setSuccess] = useState(false);
  const [validationText, setValidationText] = useState(false);
  const [proggres, setProggres] = useState(false);

  const BasicAlerts = (message) => {
    return (
      <Stack sx={{ width: "100%" }} spacing={2}>
        <Alert severity="success">{alertMessage}</Alert>
      </Stack>
    );
  };

  const handleReset = (event) => {
    event.preventDefault();
    setAlertMessage(false);
    setValidationText(false);
    const valid = validateField("email", email)
    !valid.valid && setValidationText(valid.error);
    valid.valid && setProggres(true);
    valid.valid && sendPasswordResetEmail(auth, email)
      .then((res) => {
        setProggres(false);
        setAlertMessage("הבקשה נשלחה, יש להכנס לתיבת המייל שלך וללחוץ על הקישור ששלחנו");
        setSuccess(true);
        console.log("success");
      })
      .catch((error) => {
        setProggres(false);
        const errorCode = error.code;
        const message =  getMessageText(errorCode);
        const errorMessage = error.message;
        setValidationText(message);
        console.log("An error has occured: ", errorCode, errorMessage);
        // setAlertMessage(errorCode);
      });
  };
  return (
    // <ThemeProvider theme={theme}>
    <Container id="Reset" component="main" maxWidth="xs" dir="rtl">
      {proggres && <LinearProggresComponent />}
      <CssBaseline />
      <Box
        p={1}
        sx={{
          textAlign: "center",
          marginTop: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: "default",
          "& .MuiOutlinedInput-root": {
            "& > fieldset": {
              borderColor: "text.border",
            },
          },
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <img
            src="https://beachcam.co.il/images/BeachCamIcon.png"
            alt="BeachCam Israel"
            width="36px"
          />
        </Avatar>
        <Typography component="h1" variant="h6" sx={{color: (theme) => theme.palette.text.titleHighy, fontSize: {xs: 22, md: 30}}}>
          איפוס סיסמה
        </Typography>
       {!alertMessage && <Typography sx={{ }} variant="body2" gutterBottom component="p">
          נא להזין כתובת  האימייל איתה נירשמת 
        </Typography>}
       {!alertMessage && <Typography variant="body2" gutterBottom component="p">
          ואנו נשלח לך באימייל קישור לשינוי הסיסמה
        </Typography>}
        <Box component="form" onSubmit={handleReset} noValidate sx={{ mt: 0 }}>
          {alertMessage ? <BasicAlerts sx={{ mt: 1, mb: 1 }} /> : null}

          {!success && <TextField
            variant="standard"
            sx={{ ...textfieldStyling }}
            margin="normal"
            required
            fullWidth
            id="email"
            label="אימייל איתו נרשמת"
            name="email"
            autoComplete="email"
            onChange={(e) => setEmail(e.target.value)}
          />}
          {validationText && <Alert severity="error">{validationText}</Alert>}
          {!success && <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            המשך
          </Button>}
        </Box>
      </Box>
    </Container>
    // </ThemeProvider>
  );
};

export default Reset;
