import  Container from '@mui/material/Container';

export default function PrivacyPolicyComponent() {
    return (
        <Container sx={{fontSize:{xs: "0.7rem", sm: "0.8rem",md: "1rem"}}}>
            <article dir="rtl" className="privacy-policy">
                <h4>מדיניות הפרטיות</h4>
                <p>חברת BeachCam Israel (להלן: "החברה") מבקשת להסדיר במסמך זה, את מדיניותה בנוגע לפרטיות משתמשי האתר והמערכת שהיא מפעילה ומנהלת (להלן: "האתר") ולהסביר את הטיפול במידע המתקבל מן המשתמשים וכיצד נעשה בו שימוש, כך שכל משתמש יוכל לקבל החלטה מושכלת אם ברצונו בחשיפת מידע אישי ובהתאם לכך להשתמש באתר.</p>
                <p>ככל שאין ברצונך לאפשר לחברה לאסוף מידע אודותיך, הינך מתבקש להפסיק את הגלישה באתר.</p>
                <p>מדיניות הפרטיות להלן מנוסחת בלשון זכר מטעמי נוחות בלבד, אך היא מיועדת לשני המינים.</p>
                <h4><strong>כללי</strong></h4>
                <ol>
                    <li>בעת השימוש באתר, נאסף מידע אודותיך אשר בחלקו עשוי לזהותך באופן אישי. למשל, בשמך, כתובתך וכיו"ב, אלו פרטים שאתה מוסר באופן אקטיבי ורצוני. חלק מהמידע הינו מידע סטטיסטי אשר אינו מזהה אותך באופן אישי ואינו נשמר יחד עם פרטיך. למשל, כתבות שקראת, עמודים בהם ביקרת, פרסומות שציפית, כתובת האינטרנט (IP) וכיו"ב.  </li>
                    <li>השימוש במידע הנאסף, נעשה בהתאם למדיניות פרטיות זו ובהתאם להוראות כל דין. </li>
                    <li>החברה שומרת לעצמה את הזכות לשנות ולעדכן מעת לעת את מדיניות הפרטיות והכל בהתאם לשיקול דעתה הבלעדי.</li>
                </ol>
                <h4><strong>איסוף מידע באמצעות קוקיז (cookies)</strong></h4>
                <ol start="4">
                    <li>החברה אוספת נתונים סטטיסטיים אודות העדפותיך באמצעות קוקיז, וזאת על מנת לשפר את חווית הגלישה האישית של המשתמשים, בין היתר, גם נתוני שימוש, כתבות בהם צפית, פרסומות, דפי גלישה, כתובות IP וכיו"ב. </li>
                    <li>כל משתמש יכול למחוק את הקוקיז בכל רגע נתון, דרך ההגדרות בדפדפן הגלישה בו הוא עושה שימוש. יחד עם זאת, מחיקת קוקיז עשויה לגרום לחלק מהשירותים באתר שלא לעבוד. </li>
                </ol>		
                <h4><strong>דיוור ישיר ומידע אישי שנמסר על ידי המשתמש</strong></h4>
                <ol start="6">
                    <li>באפשרותך לקבל מאיתנו עדכונים ישירות לדוא"ל האישי שלך, באמצעות הרשמה ומסירת פרטים מזהים. אלו פרטים שמזהים אותך באופן אישי ואותם אתה מזין בצורה עצמאית ובהסכמה מלאה, כדוגמת שם, תפקיד, חברה ודוא"ל. בכל עת, תוכל לבטל את הסכמתך.</li>
                </ol>		
                <h4><strong>מסירת מידע לצד שלישי</strong></h4>
                <ol start="7">
                    <li>
                    החברה לא תעביר את פרטיך האישיים והמידע שנאסף אודותיך באתר אלא במקרים המפורטים להלן:
                        <ul>
                            <li>חשיפת פרטים או מידע שהחברה מחויבת בחשיפתם בהתאם לכל דין או צו שיפוטי.</li>
                            <li>במקרה של סכסוך, תביעה או כל הליך משפטי בינך לבין החברה שתחייב את חשיפת פרטיך או המידע אודותיך. </li>
                            <li>בכל מקרה בו תפעל או תנסה לפעול בניגוד לתנאי השימוש או במקרה שתפעל כנגד החברה או בקשר אליה בניגוד לדין.</li>
                            <li>כאשר החברה תסבור כי מסירת פרטיך או המידע אודותיך הם חיוניים כדי למנוע נזק משמעותי לך או לצד שלישי.</li>
                            <li>אם החברה תמכור או תעביר את פעילות האתר לחברה כלשהי, וכן במקרה של מיזוג החברה עם חברה אחרת, ובלבד שאותה חברה תקבל על עצמה את מדיניות פרטיות זו.</li>
                            <li>החברה רשאית להעביר את פרטיך ומידע אודותיך לחברות קשורות ובלבד שהן יקבלו על עצמן את מדיניות פרטיות זו.</li>
                            <li>במקרה שתרכוש מוצרים מצדדים שלישיים המציעים אותם למכירה באמצעות האתר, המידע שיועבר במקרה זה, יהיה המידע הדרוש להם להשלמת הליך הרכישה.</li>
                            <li>שירותים מסוימים באתר ניתנים על ידי צדדים שלישיים, במקרה שכזה, יועבר המידע שאתה מסכים להעבירו. למשל, במקרה של שליחת קורות חיים דרך מדור הדרושים. </li>
                            <li>כל פרט או מידע שתסכים שיעבור לצדדים שלישיים, אשר עושים שימוש באתר, לדוגמא, ככל שתגיש מועמדות למשרות המתפרסמות במדור הדרושים באתר.</li>
                            <li>באפשרותך להצטרף לעמודי האתר ברשתות החברתיות השונות, הן באמצעות דפדפני האינטרנט והן באמצעות אפליקציות, אשר עשויות להעביר מידע אודותיך לצדדים שלישיים ואף לגולשים אחרים ברשתות החברתיות. כאשר אתה מאשר את השימוש באפליקציות וברשתות החברתיות, אתה מסכים לכך ומאשר כי הדבר הובא לידיעתך ולא תשמע אף טענה בעניין כנגד החברה. </li>
                        </ul>
                    </li>
                </ol>		
                <h4><strong>פרסומות באתר</strong></h4>
                <ol start="8">
                <li>חלקן של הפרסומות באתר מופעלות על ידי גורמים שלישיים וממחשביהם ושרתיהם. לצורך ניהול הפרסומות, גורמים אלו עושים שימוש בקוקיז ובמשבצות משוואות רשת (web beacons). המידע שנאסף על ידם, לא מזהה אותך אישית, אלא מבקש להתאים את הפרסומות כך שיתאימו להעדפותיך האישיות. חשוב לציין כי אותם גורמים העושים שימוש בקוקיז ובמשבצות משוואות רשת, לא כפופים למדיניות פרטיות זו, אלא למדיניות הפרטיות שלהם. למידע נוסף או למחיקת קוקיז ומשוואות רשת תוכל להיכנס להגדרות הדפדפן בו אתה עושה שימוש. </li>
                </ol>
                <h4><strong>אבטחת מידע</strong></h4>
                <ol start="9">
                <li>האתר מנוהל תחת רמת אבטחה נאותה הנדרשת על פי דין, אך אין בכך כדי לחסום באופן מוחלט מפני כל פריצה. </li>
                </ol>	
                <Container sx={{m: "2em auto", width: 80  }}>
                <img
                    src="https://beachcam.co.il/images/BeachCamIcon.png"
                    alt="BeachCam Israel"
                    width="36px"
                />
                </Container>
            </article>
        </Container>
    )
}