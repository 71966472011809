import React from "react";
import "./index.css";
import App from "./App";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
// import LocalStorage from "./utils/LocalStorage";
import ReactDOM from "react-dom";
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

let persistor = persistStore(store);
const rootNode = document.getElementById("root");
// console.log("LocalStorage:", LocalStorage());

ReactDOM.render(
  <Provider store={store}>
     <PersistGate persistor={persistor}>
    <App tab="home" />
    </PersistGate>
  </Provider>,
  rootNode
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
