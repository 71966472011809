import React from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Typography from "@mui/material/Typography";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import { CardActionArea } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import SellIcon from "@mui/icons-material/Sell";
import { pricingList } from "./../../data/pricinglist";

export const PricingComponent = () => {
  let url = new URL(document.location);
  let params = url.searchParams;
  let cameraNumber = params.get("camera");
  const user = useSelector((state) => state.auth.value);
  return (
    <React.Fragment>
      {/* <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      /> */}
      {/* <CssBaseline /> */}

      {/* Hero unit */}
      <Container
        className="PricingComponent"
        disableGutters
        maxWidth="sm"
        component="main"
        sx={{ pt: 0, pb: 2, px: 2 }}
      >
        <Typography
          component="h1" variant="p" sx={{ m: 1, color: (theme) => theme.palette.text.titleHighy, fontSize: { xs: 22, md: 30 } }}
          align="center"
          color="text.primary"
        // gutterBottom
        >
          מחירים
        </Typography>
        <div className="flex-justify-center">
          <Avatar sx={{ mb: 1, bgcolor: "primary.main" }}>
            <SellIcon />
          </Avatar>
        </div>

        <Typography
          sx={{ fontSize: { xs: 12, sm: 18, md: 22 } }}
          variant="h6"
          align="center"
          color="text.secondary"
          component="p"
        >
          כל החבילות כוללות צפייה ישירה בכל המצלמות
        </Typography>
        <Typography
          sx={{ fontSize: { xs: 12, sm: 18, md: 22 } }}
          variant="h6"
          align="center"
          color="text.secondary"
          component="p"
        >
          ללא פירסומות והפרעות
        </Typography>
        <Typography
          sx={{ fontSize: { xs: 12, sm: 18, md: 22 } }}
          variant="h6"
          align="center"
          color="text.secondary"
          component="p"
        >
          גלישה משופרת
        </Typography>
        <Typography
          sx={{ fontSize: { xs: 12, sm: 18, md: 22 } }}
          variant="h6"
          align="center"
          color="text.secondary"
          component="p"
        >
          צפייה רציפה ומשודרגת
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {pricingList.map((element) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={element.title}
              xs={12}
              sm={element.title === "Enterprise" ? 12 : 6}
              md={4}
            >
              <Card sx={{ maxWidth: "95%", m: "0 auto" }}>
                <CardActionArea
                  href={!user ? element.buttonHref + "?camera=" + cameraNumber : "/meshulampaymentpage"}
                >
                  <CardHeader
                    // dir="rtl"
                    title={element.title}
                    subheader={element.subheader}
                    titleTypographyProps={{ align: "center" }}
                    // action={
                    //   element.title === "Pro" || "שבועיים ניסיון חינם" ? (
                    //     <StarIcon  sx={{color: (theme)=> theme.palette.text.orange}}/>
                    //   ) : null
                    // }
                    subheaderTypographyProps={{
                      align: "center",
                    }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                      color: (theme) => theme.palette.text.titleHighy,
                    }}
                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                      }}
                    >
                      <Typography
                        component="h5"
                        variant="h5"
                        color="text.primary"
                        sx={{ direction: "rtl" }}
                      >
                        {element.price}
                        {' ש"ח '}
                      </Typography>
                      <Typography variant="h6" color="text.secondary">

                      </Typography>
                    </Box>
                    <ul>
                      {element.description.map((line) => (
                        <Typography
                          sx={{}}
                          component="li"
                          variant="body2"
                          align="center"
                          key={line}
                        >
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    <Button fullWidth variant={element.buttonVariant} sx={{ mb: 1 }}>
                      {element.buttonText}
                    </Button>
                  </CardActions>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </React.Fragment>
  );
};
