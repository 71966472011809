import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import SellIcon from "@mui/icons-material/Sell";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import HomeIcon from "@mui/icons-material/Home";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { signOut, getAuth } from "firebase/auth";
import LightModeIcon from "@mui/icons-material/LightMode";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Link from "@mui/material/Link";
import { saveUserInfo } from "../redux/slice/userInfoSlice";
import { saveUserPackage } from "../redux/slice/userPackageSlice";
import { useDispatch } from "react-redux";
import { Block } from "@mui/icons-material";

const DrawerListComponent = (props) => {
  const {
    anchor,
    toggleDrawer,
    url,
    cameraNumber,
    checked,
    handleChange,
    light,
    user,
    userPackage
  } = props;
  const auth = getAuth();
  const dispatch = useDispatch();


  return (
    <Box
      dir="rtl"
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="end"
        sx={{
          justifyContent: "right",
          mr: 1,
          alignSelf: "flex-end",
          display: { xs: "block", sm: "block", md: "none" },
        }}
        onClick={toggleDrawer(anchor, true)}
      >
        <MenuIcon fontSize="large" />
      </IconButton>
      <div className="mobile-logo">
        <IconButton
          sx={{
            ml: 2,
            mt: 0,
            display: { xs: "block", sm: "block", md: "none" },
          }}
        >
          <img
            src="https://beachcam.co.il/images/BeachCamIcon.png"
            alt="BeachCam Israel"
            width="36px"
          />
        </IconButton>
      </div>

      <List>
        {[
          { text: "החשבון שלי", path: "/myaccount", icon: <AccountCircleIcon /> },
          { text: "מצלמות", path: "/cameras", icon: <PhotoCamera /> },
          // { text: "דווח מהחוף", path: "/videoForcast" },
          { text: "בית", path: "/", icon: <HomeIcon /> },
          { text: "מחירים", path: "/pricing", icon: <SellIcon /> },
          { text: "יצירת דווח", path: "/videouploader" },
          // { text: "יציאה", path: url.pathname },
        ].map((obj, index) => (
          <ListItem
            sx={{ display: (obj.path !== "/videouploader" || (obj.path === "/videouploader" && user && userPackage && userPackage.reporter === 1)) ? "flex" : "none" }}
            selected={obj.path === url.pathname ? true : false}
            button
            key={obj.text}
            onClick={() =>
              !cameraNumber || cameraNumber === "null"
                ? (window.location = obj.path)
                : (window.location = obj.path + "?camera=" + cameraNumber)
            }
          >
            <ListItemIcon sx={{ color: (theme) => theme.palette.text.primary }}>
              {obj.icon ? obj.icon : <LiveTvIcon />}
            </ListItemIcon>
            <ListItemText
              primary={obj.text}
              sx={{ ml: 2, textAlign: "right", display: { sm: "block" } }}
            />
          </ListItem>
        ))}
      </List>
      <FormGroup sx={{ mx: 1.5, pr: 2, flexDirection: "row" }}>
        <FormControlLabel
          control={
            <Switch
              name="themeSwitch"
              color="default"
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          labelPlacement="start"
          label={
            <span style={{ color: light ? "#000000de" : "#ff9800" }}>
              <LightModeIcon />
            </span>
          }
        />
      </FormGroup>
      <Divider />

      {user && (
        <Button
          // href="/"
          variant="outlined"
          sx={{ my: 2, mx: 6 }}
          onClick={() => {
            dispatch(saveUserInfo(undefined));
            dispatch(saveUserPackage(undefined));
            signOut(auth)
              .then(() => {
                localStorage.removeItem("user");
              })
              .catch((error) => {
                console.log("error", error);
              });
          }}
        >
          לצאת מהמערכת
        </Button>
      )}
      {!user && (
        <Button
          // href="/"
          variant="outlined"
          sx={{ my: 2, mx: 2 }}
          onClick={() => (window.location = "/cameras?camera=" + cameraNumber)}
        >
          כניסה למנויים
        </Button>
      )}

      {!user && url.pathname !== "/signup" && (
        <Button
          fullWidth
          href={"/signup?camera=" + cameraNumber}
          // variant="outlined"
          color="primary"
        >
          {"עדיין אין לך חשבון? ליצירת חשבון"}
        </Button>
      )}
      <Divider />
    </Box>
  );
};

export default DrawerListComponent;
