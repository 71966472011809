import React, { useState } from 'react';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DuoIcon from '@mui/icons-material/Duo';




const VideoReportComponent = () => {

  const videoURL = "https://firebasestorage.googleapis.com/v0/b/mbc-dev-4c869.appspot.com/o/video%2FvideoForcast?alt=media&token=a50550af-aa0a-4235-ba67-80fb68e1d9b7&_gl=1*5fjoym*_ga*NTI4MDcxNTU0LjE2ODE4MjExMjE.*_ga_CW55HF8NVT*MTY4NjQwMTQwNC4xMjguMC4xNjg2NDAxNTI0LjAuMC4w";


  return (
    <Container className="    -container text-center" component="main"
      maxWidth="md"
      sx={{backgroundColor: "#ffffff"}}
    >


      {videoURL &&
        <video width={"100%"} height={"100%"} controls>
          <source src={videoURL} type="video/mp4" />
        </video>

      }
      
  <DuoIcon/>
  <div>My Beach Cam</div>
    </Container>
  );
};

export default VideoReportComponent;
