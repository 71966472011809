import { createSlice } from "@reduxjs/toolkit";

const initialState = {value: true};

export const deviceIdSlice = createSlice({
  name: "deviceId",
  initialState,
  reducers: {
    setDeviceId: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setDeviceId } = deviceIdSlice.actions;

export default deviceIdSlice.reducer;
