import React from "react";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import SellIcon from "@mui/icons-material/Sell";
import LiveTvIcon from "@mui/icons-material/LiveTv";

export const optionsList = [
    {
      title: "הרשמה",
      subheader: "למנויים חדשים",
      price: "0",
      description: [
        "חינם - ללא תשלום",
        "צפייה רצופה",
        "ללא פירסומות",
        "אזהרה: הצפייה בתוכן זה",
        "ממכר",
        "להרשמה עכשיו - כאן",
      ],
      buttonText: "הרשמה חינם",
      buttonVariant: "outlined",
      linkUrl: "/signup",
      icon: <AppRegistrationIcon />,
    },
    {
      title: "מצלמות",
      subheader: "צפייה למנויים רשומים",
      price: "פחות מ 1 שקל ליום",
      description: [
        "האיכות של ביץ`-קאם",
        "צפייה בכל מכשיר",
        "למנויי פרו",
        "ללא פירסומות והפרעות",
        "כניסת מנויים כאן"
      ],
      buttonText: "כניסה למצלמות",
      buttonVariant: "contained",
      linkUrl: "/cameras",
      icon: <LiveTvIcon />,
    },
    {
      title: "מחירון",
      subheader: "פחות משקל ליום",
      price: "0",
      description: [
        "מגוון תוכניות ומחירים",
        "ללא התחייבות",
        "מבצעים עכשיו ! לרגל ההשקה",
        "תשלום מאובטח",
        "במגוון אמצעי תשלום",
      ],
      buttonText: "Sign up for free",
      buttonVariant: "outlined",
      linkUrl: "/pricing",
      icon: <SellIcon />,
    },
  ];