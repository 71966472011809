import React from "react";
import { useEffect, useState } from "react"
import Avatar from "@mui/material/Avatar";
import { useSelector } from "react-redux";
import { applyActionCode, getAuth } from "firebase/auth";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import LinearProggresComponent from "../../components/LinearProggresComponent";
import { setEmailValidByUid } from "../../api/db";
import AuthHandleResetPassword from "./AuthHandleResetPassword";
import Typography from "@mui/material/Typography";

const EmailVerificationHandlePage = () => {
    const userInfo = useSelector((state) => state.userInfo.value);
    const [verified, setVerified] = useState(false);
    const [error, setError] = useState(false);
    const [proggres, setProggres] = useState(false);
    const auth = getAuth();
    const userPackage = useSelector((state) => state.userPackage.value);

    const getParameterByName = (paramterName) => {
        let url = new URL(document.location);
        let params = url.searchParams;
        let parame = params.get(paramterName);
        return parame
    }

    const actionCode = getParameterByName('oobCode');
    const mode = getParameterByName('mode');

    useEffect(() => {
        actionCode && mode === "verifyEmail" && setProggres(true);
        actionCode && mode === "verifyEmail" && applyActionCode(auth, actionCode)
            .then(() => {
                console.log(" Email address has been verified.")
                setProggres(false);
                setVerified(true);
            })
            .catch((error) => {
                console.log(" Code is invalid or expired, verify Email again.")
                setProggres(false);
                setError(true);
            });
        // Specify how to clean up after this effect:
        return function cleanup() {

        };
    }, [auth, actionCode, mode]);

    useEffect(() => {
        verified &&
            userInfo &&
            userPackage &&
            userPackage.uid &&
            userInfo.email &&
            setEmailValidByUid(userPackage.uid, userInfo.email)
                .then((resp) => {
                    console.log('setEmailValidByUid - respose:', resp)
                })
                .catch((error) => {
                    console.log('setEmailValidByUid-fail:', error)
                });
        // Specify how to clean up after this effect:
        return function cleanup() {

        };
    }, [userInfo, userPackage, verified]);

    return (
        <div className="text-center pad-x-1">
            {proggres && <LinearProggresComponent />}
            {mode === "verifyEmail" && <Box id="EmailVerificationHandlePage" sx={{ mt: 3 }}>
                {userInfo && userInfo.email && <span>{userInfo.email}</span>}
                {verified && <Typography color={"green"} variant="h5" gutterBottom component="div">
                    האימייל שלך אושר
                </Typography>}
                {verified &&
                    <Button
                        variant="contained"
                        onClick={() => window.location = "cameras"}
                    // size="medium"
                    >
                        כניסה
                    </Button>}
                {error && <Typography variant="body1" gutterBottom component="div" sx={{ mt: 7 }}>
                    קוד האישור לא בתוקף, יש לאשר את האימיל שוב ע"י לחיצה על הקישור ששלחנו לאימיל
                </Typography>
                }
                <div className="flex-justify-center">
                    <Avatar sx={{ m: 1, ml: 2, bgcolor: "inherit", width: '60px', height: "60px" }}>
                        <img
                            src="https://beachcam.co.il/images/BeachCamIcon.png"
                            alt="BeachCam Israel"
                            width="50px"
                        />
                    </Avatar>
                </div>
                <Typography component="h1" variant="h5" sx={{ pb: 2, color: (theme) => theme.palette.text.titleHighy, fontSize: { xs: 22, md: 30 } }}>
                    My Beach Cam
                </Typography>
            </Box>}
            {mode === "resetPassword" && <AuthHandleResetPassword actionCode={actionCode} auth={auth} />}
        </div>
    );
};

export default EmailVerificationHandlePage;
