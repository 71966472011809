import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useState } from 'react';

export default function DatePickerComponent(props) {
    const {setSelectedNum} = props; 
    const [value, setValue] = useState("2023-07-22");
    const handler = (e) => {
        e.preventDefault();
        setValue(e.target.value);
        setSelectedNum(e.target.value);
        console.log("e.target.value:", e.target.value)
    }

    return (
        <div>
            <TextField type="date" id="start" name="trip-start"
                value={value}
                label="יש לבחור תאריך סיום המנוי"
                onChange={(e)=>handler(e)}
                min="2023-05-01" max="2033-12-31"></TextField>
        </div>
    );
}