// Import the functions you need from the SDKs you need

// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyDIG6wtM8Eu8Ybzk_WVPsdlW8fTjv9bego",
  authDomain: "mbc-dev-4c869.firebaseapp.com",
  databaseURL: "https://mbc-dev-4c869-default-rtdb.europe-west1.firebasedatabase.app/",
  projectId: "mbc-dev-4c869",
  storageBucket: "mbc-dev-4c869.appspot.com",
  messagingSenderId: "770266865872",
  appId: "1:770266865872:web:bfab212227f8491654fed5",
  measurementId: "G-F84M1KGSD6"
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);