import React from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from "react-redux";
import {useEffect} from "react"

const TranzilaPaymentPage = () => {
  let url = new URL(document.location);
  let params = url.searchParams;
  let cameraNumber = params.get("camera");
  const matches = useMediaQuery('(min-width:600px)');
  const userInfo = useSelector((state) => state.userInfo.value);

  const iframeDidLoad = (e)=>{
    console.log("iframeLoad", e.target.src)
  }

  
  
  console.log("PaymentUserInfo",userInfo)
  useEffect(() => {
    const handleMessage =(event)=> {
      // console.log("messageOrigin:", event.origin);
      if(event.origin === "https://meshulam.co.il"){
        console.log("messageOrigin:", event.origin);
        console.log("messageData.Data:", event.data);
        console.log("messageData.Action:", event.data.action);
        console.log("messageData.status:", event.data.status);
      }
    };
    window.addEventListener('message', (event) => {
      console.log(`Received message: ${event.data}`);
      handleMessage(event);
  });
    return () => {
        window.removeEventListener("message", handleMessage);
    };
}, []);

  return (
    // <div className=" pad-x-1">
          // transform: matches? 'translate(0px ,-60px) scale(0.7)': null,
      <div id="tranzila-iframe-container" className="tranzila-iframe-container">
        <iframe
          onLoad= {(e)=>iframeDidLoad(e)}
          title="my beach cam payment page"
          id="tranzila1"
          src="https://direct.tranzila.com/testsales/"
          // src="https://pay.tranzila.com/testsales/UDk2M1hrYnZNYm4rV1JzYkFTcXkrdz09"
          width="100%"
          height="100%"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
  );
};

export default TranzilaPaymentPage;
