import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

export default function MenuPopupStateComponent(prop) {
  const { selection, updateOptions, callBack, label } = prop;
  const options = selection !== "close" ? updateOptions[selection] : null;
  const handeleSelction = (selected) => {
    callBack(selected)
  }
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <Button variant="outlined" {...bindTrigger(popupState)} sx={{ mt: "23px" }}>
            {label}
          </Button>
          <Menu {...bindMenu(popupState)}>
            {selection && options &&
              Object.keys(options).map(function (key) {
                return <MenuItem
                  sx={{ direction: "rtl" }}
                  onClickCapture={() => handeleSelction(key)}
                  onClick={popupState.close}
                  key={options[key].code}
                  value={key["name"]}>
                  {options[key].name}
                </MenuItem>
              })
            }
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
}