import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

const footers = [
  {
    title: "משפטי",
    details: [{name: "מדיניות פרטיות",  href: "/privacypolicy"},
            {name: "תנאי שימוש", href: "/termsofuse" }],
   
  },
  {
    title: "פיצ'רים",
    details: [
      {name:"מצלמות חוף", href: "/cameras"},
      {name: "הרשמה",  href: "/signup"},
      {name:"מחירים", href: "/pricing"},
      {name:"אזור אישי", href: "/myaccount"},
      {name:"דף הבית", href: "/"},
    ],
  },
  {
    title: "החברה",
    details: [
      {name:"הצוות", href: "#"},
      {name: "היסטוריה",  href: "#"},
      {name:"יצירת קשר", href: "/contactus"},
      // {name:"מיקום", href: "#"},   
    ]
  },

  {
    title: "מקורות",
    details: [
      {name: "עזרה",  href: "/help"},
      // {name:"Resource name", href: "#"},
      // {name:"Another resource", href: "#"},
      // {name:"Final resource", href: "#"},
    ],
  },
];

const FooterComponent = () => {
  return (
    <Container
      maxWidth="md"
      component="footer"
      sx={{
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        mt: 8,
        py: [3, 6],
      }}
    >
      <Grid container spacing={4} justifyContent="space-evenly" dir="rtl">
        {footers.map((footer) => (
          <Grid item xs={6} sm={3} key={footer.title}>
            <Typography variant="h6" color="text.primary" sx={{fontSize:{xs: "0.9rem",sm: "1rem",md: "1.25rem"}}} gutterBottom>
              {footer.title}
            </Typography>
            <ul>
              {footer.details.map((item) => (
                <li key={item.name}>
                  <Link href={item.href?item.href: null } variant="subtitle1" sx={{fontSize:{xs: "0.7rem", sm: "0.8rem",md: "1rem"}}} color="text.secondary">
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default FooterComponent;
