import { createSlice } from "@reduxjs/toolkit";

const initialState = {value: false};

export const ProggresSlice = createSlice({
  name: "proggres",
  initialState,
  reducers: {
    setProggres: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setProggres } = ProggresSlice.actions;

export default ProggresSlice.reducer;