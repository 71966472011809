export const updateOptions = {
    optionsNames:{
        packageEndsDate: "אפשרויות זמן להוספה",
        packageName: "בחירת סוג מנוי",
        disablePackage: "זמן הקפאה"


    },
    packageEndsDate: {
        option1: {
            code: "days",
            name: "ימים"
        },
        option2: {
            code: "weeks",
            name: "שבועות"
        },
        option3: {
            code: "months",
            name: "חדשים"
        },
        option4: {
            code: "years",
            name: "שנים"
        },
        option5: {
            code: "customDate",
            name: "עד תאריך"
        },
    },
    packageName: {
        option1: {
            code: "vip",
            name: "vip",
        },
        option2: {
            code: "pro1",
            name: "פרו חודשי מתחדש",
        },
        option3: {
            code: "pro6",
            name: "פרו חצי שנתי",
        },
        option4: {
            code: "pro12",
            name: "פרו שנתי ",
        }
    },
    disablePackage: {
        option1: {
            code: "disable_tiilEndsDate",
            name: "עד תאריך",
        },
        option2: {
            code: "disable_FromNow",
            name: "הקפאה מיידית",
        },
        option3: {
            code: "disable_FromStartsDate",
            name: "החל מתאריך",
        },
    }
}