import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useLocation } from "react-router-dom";
import { validateField, getMessageText } from "../../utils/validation";
import { minWidth } from "@mui/system";
import { useSelector, useDispatch } from "react-redux";
import { textfieldStyling } from "../../theme/theme.js";
import { red } from "@mui/material/colors";
import { setProggres } from "../../redux/slice/proggresSlice";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import LinearProggresComponent from "../../components/LinearProggresComponent.js";
import { setDeviceIdByUid } from "../../api/db.js";
import { v4 as uuidv4 } from 'uuid';



const alertColor = red[500]; // #f44336

export default function SignIn() {
  const proggres = useSelector((state) => state.proggres.value);
  const dispatch = useDispatch();
  const auth = getAuth();
  const [fieldsValid, setFieldsValid] = useState({
    email: null,
    password: null,
  });
  const [alertMessage, setAlertMessage] = useState(false);
  const [formErrors, setFormErrors] = useState({ email: null, password: null });
  const pathName = useLocation().pathname;
  const url = new URL(document.location);
  const params = url.searchParams;
  const cameraNumber = params.get("camera");

  const onFieldChange = (e, label) => {
    const validation = validateField(label, e.target.value);
    const { error, valid, fieldName } = validation;
    setAlertMessage((state) => (state ? null : null));
    setFormErrors((formErrors) => ({
      ...formErrors,
      [label]: error,
    }));
    setFieldsValid((fieldsValid) => ({
      ...fieldsValid,
      [fieldName]: valid,
    }));
  };

  const signInToServer = (event) => {
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const password = data.get("password");
    dispatch(setProggres(true));
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        dispatch(setProggres(false));
        const user = userCredential.user;
        const newDeviceId = uuidv4();
        localStorage.setItem("localDeviceId", JSON.stringify(newDeviceId));
        setDeviceIdByUid(user.uid, newDeviceId)
          .then(res => console.log("newDeviceId"))
          .catch(error => console.log("error:", error))
        // console.log("Singed in user: ", user);
        // if (pathName === "/signin")
        //   window.location =
        //     cameraNumber !== "null"
        //       ? "/cameras?camera=" + cameraNumber
        //       : "/cameras";
      })
      .catch((error) => {
        dispatch(setProggres(false));
        const errorCode = error.code;
        const errorMessage = error.message;
        const message = getMessageText(errorCode);
        setAlertMessage(message);

        console.log("An error occured: ", errorCode, errorMessage);
      });
  };

  const runValidation = (key, val) => {
    const validation = validateField(key, val);
    const { error, valid, fieldName } = validation;
    setAlertMessage((state) => (state ? null : null));
    setFormErrors((formErrors) => ({
      ...formErrors,
      [fieldName]: error,
    }));
    setFieldsValid((fieldsValid) => ({
      ...fieldsValid,
      [fieldName]: valid,
    }));
    return valid;
  };

  const handleLogin = (event) => {
    event.preventDefault();
    setAlertMessage(false);
    const data = new FormData(event.currentTarget);
    let userData = {};
    let formValid = true;
    setAlertMessage(false);
    for (let key of data.keys()) {
      let val = data.get([key]);
      const valid = runValidation(key, val);
      if (!valid) {
        formValid = false;
      }
      userData[key] = val;
    }

    fieldsValid.email && fieldsValid.password && formValid && signInToServer(event);
  };

  return (
    <Container id="SignIn" component="main" maxWidth="xs" dir="rtl">
      {proggres && <LinearProggresComponent />}
      <Box
        p={1}
        sx={{
          marginTop: 1,
          marginBottom: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: "default",
          "& .MuiOutlinedInput-root": {
            "& > fieldset": {
              borderColor: "text.border",
            },
          },
        }}
      >
        <Avatar sx={{ bgcolor: "primary.main" }}>
          <img
            src="https://beachcam.co.il/images/BeachCamIcon.png"
            alt="BeachCam Israel"
            width="36px"
          />
        </Avatar>
        <Typography component="h1" variant="h5" sx={{color: (theme) => theme.palette.text.titleHighy, fontSize: {xs: 22, md: 30}}}>
          כניסת מנויים BeachCam
        </Typography>
        <Typography variant="subtitle1" gutterBottom component="div">
          יש להזין אימייל וסיסמה
        </Typography>
        <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
          <TextField
            variant="standard"
            sx={{ ...textfieldStyling }}
            margin="normal"
            fullWidth
            id="email"
            label="כתובת אימייל"
            name="email"
            autoComplete="email"
            onChange={(e) => onFieldChange(e, "email")}
          />
          {/* <FormControl
          sx={{width: "100%"}}
          //  sx={{ ...textfieldStyling }}
           >
         
          <OutlinedInput
            // sx={{ ...textfieldStyling }}
            margin="normal"
            required
            fullWidth
            id="email"
            label="כתובת אימייל"
            name="email"
            autoComplete="email"
            onChange={(e)=>onFieldChange(e,"email")}
          />
           <InputLabel htmlFor="sandbox" fullWidth variant="filled" sx={{
            right:()=>InputFilld()?"auto":"40px",
            left: ()=>InputFilld()? 0: "auto",
            // top: ()=>InputFilld()? "30px": "auto",
            display: ()=>InputFilld()?"none":"auto"
          }}>
          כתובת אימייל 
        </InputLabel>
            <MyFormHelperText />
          </FormControl > */}
          {formErrors.email && (
            <Box component="span" variant="p" sx={{ color: alertColor, mx: 2 }}>
              {formErrors.email}
            </Box>
          )}
          <TextField
            variant="standard"
            sx={{ ...textfieldStyling }}
            margin="normal"
            fullWidth
            name="password"
            label="סיסמה"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => onFieldChange(e, "password")}
          />
          {formErrors.password && (
            <Box component="div" variant="p" sx={{ color: alertColor, mx: 2 }}>
              {formErrors.password}
            </Box>
          )}
          {/* {alertMessage && (
            <Box component="div" variant="p" sx={{ color: alertColor, mx: 2 }}>
              {alertMessage}
            </Box>
          )} */}
          {alertMessage && (<Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="error">
              {alertMessage ? alertMessage : null}
            </Alert>
          </Stack>)}

          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="זכור אותי"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            המשך
          </Button>
          <Grid container dir={"ltr"}>
            <Grid item>
              <Link href={"/signup?camera=" + cameraNumber} variant="body2">
                {"עדיין אין לך חשבון? ליצירת חשבון"}
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Link href={"/reset?camera=" + cameraNumber} variant="body2">
                שכחתי סיסמה
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
