export const pricingList = [
    {
      title:   "שבועיים ניסיון חינם",
      subheader: "..קחו אותי לאקשן",
      price: "0",
      description: [
      "מנוי פרו לניסיון",
        "ללא תשלום",
        "ללא פירסומות",
        "צפייה רציפה ללא הפסקות",
        "שידרוגים, פיצ'רים ומצלמות חדשות",
      ],
      buttonText: "הרשמה חינם",
      buttonVariant: "contained",
      buttonHref: "/signup",
    },
    {
      title: "פרו - שנתי",
      subheader: "! כולל 4 חודשים חינם",
      price: "240",
      description: [
        'במקום 360 ש"ח',
        "! הכי משתלם",
        "פחות מ 70 אגורות ליום*",
        "המבצע לרגל ההשקה",
        '***',
        "מתנה 4 חודשים חינם",
        "משלמים רק על 8 חודשים",
        'שווי ההנחה 120 ש"ח',
        ' תשלום לשנה רק 240 ש"ח',
      ],
      buttonText: "הרשמה",
      buttonVariant: "contained",
      buttonHref: "/signup",
    },
    {
      title: "מנוי לחודש",
      price: "30",
      description: [
        'מתחדש מדי חודש',
        "מחיר קבוע",
        "לגלוש קבוע",
        "משלמים רק על חודש אחד כל פעם",
        '',
        'תשלום חודשי 30 ש"ח',
      ],
      buttonText: "להרשמה",
      buttonVariant: "outlined",
      buttonHref: "/signup",
    },
    {
      title: "פרו - חצי שנתי",
      price: "150",
      description: [
        'במקום 180 ש"ח',
        "מבצע לרגל ההשקה",
        "***",
        "משלמים רק על 5 חודשים",
        "מקבלים חודש נוסף חינם",
        'שווי ההנחה 30 ש"ח',
        ' תשלום לתקופה 150 ש"ח',
      ],
      buttonText: "להרשמה",
      buttonVariant: "outlined",
      buttonHref: "/signup",
    },
    
  ];