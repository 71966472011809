export const validateField = (fieldName, value) => {
  let valid = true;
  let error = false;

  switch (fieldName) {
    case "email":
      if (value.length === 0) {
        valid = false;
        error = "שדה חובה";
      } else {
        valid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        error = valid ? false : " אימייל לא חוקי";
      }
      break;
    case "password":
      if (value.length <= 5) {
        valid = false;
        error = "סיסמה קצרה מדי";
      } else {
        if (value.length >= 16) {
          valid = false;
          error = "סיסמה ארוכה מדי";
        }
      }
      if (value.length === 0) {
        valid = false;
        error = "שדה חובה";
      }
      if (!value) {
        valid = false;
        error = "שדה חובה";
      }
      break;
    case "firstName":
      if (value.length <= 0) {
        valid = false;
        error = "שדה חובה";
      } else {
        if (value.length >= 13) {
          valid = false;
          error = "יש להזין שם קצר יותר";
        }
      }
      break;
    case "lastName":
      if (value.length <= 0) {
        valid = false;
        error = "שדה חובה";
      } else {
        if (value.length >= 13) {
          valid = false;
          error = "יש להזין שם קצר יותר";
        }
      }
      break;
    default:
      break;
  }
  valid ? valid = true : valid = null;
  return {
    fieldName: fieldName,
    valid: valid,
    error: error
  };
};

export const getMessageText = (errorCode) => {
  let text = false;
  switch (errorCode) {
    case "auth/network-request-failed":
      text = "החיבור נקטע, יש לנסות שוב";
      break;
    case "auth/invalid-dynamic-link-domain":
      text = "אין כניסה מכתובת זו";
      break;
    case "auth/invalid-email":
      text = "האימייל לא תקין";
      break;
    case "auth/invalid-password":
      text = "הסיסמה לא תקינה";
      break;
    case "auth/missing-uid":
      text = "מצטערים, תקלה";
      break;
    case "auth/uid-already-exists":
      text = "משהו לא תקין";
      break;
    case "auth/user-not-found":
      text = "המשתמש לא קיים במערכת";
      break;
    case "auth/wrong-password":
      text = "אימייל וסיסמה לא תואמים";
      break;
    case "auth/email-already-in-use":
      text = "אימייל קיים במערכת";
      break;
    case "auth/too-many-requests":
      text = "יותר מדי ניסיונות כשלו";
      break;
    default:
      text = "לא אושרה הכניסה";
      break;
  }
  return text;
}