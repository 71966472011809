import Container from '@mui/material/Container';

export default function TermsOfUseComponent() {
    return (
        <Container sx={{ fontSize: { xs: "0.7rem", sm: "0.8rem", md: "1rem" } }}>
            <article dir="rtl" className="privacy-policy">
                <h4>תנאי השימוש - MyBeachCam</h4>
                <h5>הגדרות</h5>
                <p>האתר/ אתר האינטרנט - אתר BeachCam, שנמצא בבעלות מצלמות חוף ישראל המספק שירותי
                    תוכן וויזואלי לצפייה ישירה באתר האינטרנט שכתובתו mybeachcam.app / beachcam.co.il</p>
                <p>האפליקציה – אפליקציית My BeachCam (  מובייל ו TV smart  ו web ) המספקת טכנולוגיה לצפייה בשירותי תוכן וויזואלי.</p>
                <p>השירות – מנוי מתחדש בתשלום עם אפשרות ביטול בכל עת,לשימוש בטכנולגיה לצפייה בתכני BeachCam ללא
                    פרסומות.</p>
                <p>סיום המנוי יהיה בכפוף לתנאי סיום המנוי שבסעיף 3 להלן.
                </p>
                <p>BeachCam תהיה רשאית לשנות ו/או לעדכן את סוגי המנויים הזמינים ללקוחותיה לפי שיקול דעתה
                    וללא הודעה מראש. </p>
                <p>משתמש – הגולש שרכש מנוי לשירות</p>
                <p>התוכן – שידורים חיים ותכני ווידיאו שונים שיוצעו לצפייה מעת לעת באתר האינטרנט או באפליקציה.  </p>
                <p>BeachCam ו/או My BeachCam – מצלמות חוף ישראל.</p>
                <h4><strong>1. תהליך רכישת המנוי</strong></h4>
                <ol>
                    <li><p>1 באתר האינטרנט ובאפליקציה קיימת הפניה לעמוד רכישת השירות, שם יחל
                        תהליך הרכישה בהתאם לשלבים שמפורטים להלן:</p></li>
                    <li><p>1 במידה שתהיה יותר מאפשרות מנוי אחת, על המשתמש לבחור בהתאם לפירוט
                        המופיע בעמוד הרכישה ולמחירים בו.</p></li>
                    <li><p>1 יובהר כי BeachCam תהיה רשאית להציג חבילת מנוי אחת בלבד, ללא בחירה בין
                        תקופות או סוגי חבילות.
                        יובהר כי לא תתאפשר קבלת החזר כספי בגין רכישת מנוי שהתבצעה. סיום המנוי
                        יתאפשר בהתאם להוראות סעיף 4 להלן.</p></li>
                    <li><p>1 מחיר השירות עשוי להשתנות מעת לעת לפי שיקול דעתה של BeachCam והמחיר
                        התקף הוא זה שמוצג בעת הרכישה במסך הסליקה בכפוף לתעריפים ולשערי
                        המטבע של חנויות הרכישה/חברות הסליקה.</p></li>
                    <li><p>1<span> התשלום</span></p></li>
                    <div>
                        א. במקרה של רכישה באפליקציה - לאחר בחירת תקופת המנוי יועבר המשתמש
                        לאישור התשלום ויוכל להתחיל להשתמש בשירות. מובהר כי הרכישה
                        באפליקציה דרך החנויות השונות (Bit, Google play , App store  )כפופה לתנאי החנויות
                        ול BeachCam אין אחריות כלפי המשתמש ו/או כל צד ג' שהוא בקשר לביצוע התשלום
                        באמצעי זה. לעניין מועד התשלום החודשי, יצוין כי אין בידי BeachCam מידע בנוגע למועדי
                        הסליקה המדויקים של חנויות האפליקציה ו/או אמצעי התשלום השונים, ועל כן,
                        המידע המופיע באפליקציה תחת "תאריך החיוב הבא" אינו מידע מחייב, אלא
                        מבוסס אך ורק על מועד רכישת המנוי.</div>
                    <p>ב. במקרה של רכישה באתר האינטרנט - לאחר בחירת תקופת המנוי יועבר
                        המשתמש לאתר סליקה לשם ביצוע התשלום. בהיכנסו לדף הסליקה ימלא
                        המשתמש אחר הוראות התשלום בין אם הינו מנוי לשירות ובין אם לא. מובהר
                        כי התשלום באמצעות paypal, bit, Google pay , Apple Pay ,כפוף לתנאי השימוש בכל אמצעי תשלום ול BeachCam אין אחריות
                        כלפי המשתמש ו/או כל צד ג' שהוא בקשר לביצוע התשלום באמצעי זה. לאחר ביצוע
                        התשלום יוחזר המשתמש
                        לאתר ורשאי להתחיל להשתמש בשירות.</p>
                </ol>

                <h4><strong>2. הצפייה בתוכן</strong></h4>
                <ol start="1">
                    <li><p>2 BeachCam תהיה רשאית, על פי שיקול דעתה הבלעדי, להשעות או לחסום, באופן זמני
                        או קבוע, את הגישה של המשתמש לשירות במקרים של עבירה על הוראות דין או
                        על תנאי השימוש או אם קיימת הפרעה מכל סוג שהוא לפעילות התקינה של
                        רשת האינטרנט או של מערכת המחשבים או פגיעה בזכויות קניין רוחני של BeachCam
                        או של צד אחר, ולא תהיה למשתמש כל טענה או דרישה כנגד BeachCam או מי מטעמה
                        במקרה כאמור. </p></li>
                    <li><p>2 BeachCam שומרת לעצמה את הזכות לשנות את תנאי ההתקשרות ואת השירותים
                        שהיא נותנת, ובכלל זאת על ידי הוספת שירותים או הפסקתם, ללא כל הודעה
                        מראש.</p></li>
                </ol>
                <h4><strong>3. סיום המנוי</strong></h4>
                <ol start="1">
                    <li><p>3 המנוי יתחדש מדי חודש או יותר בהתאם לתקופת המנוי עד שיבוטל על ידי המשתמש. יובהר, כי ביטול המנוי יכנס
                        לתוקף בתום תקופת החודש לא קלנדרי בו נשלחה הבקשה. לדוגמה, מנוי
                        שהתחיל ב- 10.6 ונשלחה בקשה לסיימו ב- 20.6, יסתיים ב- 10.7 והמנוי לא יחוייב
                        בגין החודש שתחילתו ב- 10.7.</p></li>
                    <li><p>3 הפסקת המנוי יכולה להתבצע בדרכים הבאות:</p></li>
                    <div>פנייה במייל ל support@beachcam.co.il</div>
                    <div>פנייה במייל ל sales@beachcam.co.il</div>
                </ol>
                <h4><strong>4. זכויות יוצרים</strong></h4>

                <ol start="1">

                    <li><p>
                        4 הטכנולוגיה והמידע הכלולים באתר האינטרנט, באפליקציה ובשירותים, לרבות כל התוכן שבו,
                        מוגנים בזכויות יוצרים ובזכויות קניין רוחני אחרות, של BeachCam או של ספקים אחרים,
                        בהתאם לחוקי מדינת ישראל, אמנות בינלאומיות, חוקי זכויות יוצרים של מדינות
                        אחרות והסכמים בין האתר וספקי התכנים השונים. מותר להשתמש בשירותים
                        רק באופן אישי ולא מסחרי ואסור להעתיק את השירותים או תוכנם, לשכפלם,
                        לשנותם, להפיצם, לשדרם, להציגם בציבור או במקום ציבורי או בפני קהל, לבצעם,
                        לפרסמם, להעניק רישיונות עבור, ליצור עבודות הנגזרות מתוך, לרבות שימוש לצרכי מחקר או להעבירם
                        הלאה, באופן זמני או קבוע, אלא אם הסכמנו לכך במפורש. ביצוע פעולות כאמור,
                        עלול לפגוע בזכויות BeachCam או בזכויות של ספקי התכנים.
                    </p></li>
                    <li><p>4 השימוש בשירות מותנה בהסכמתך ובהתחייבות שלך כי לא תעשה או תבצע או
                        תהיה מעורב בביצוע פעולות המפרות תנאי שימוש אלה, ובפרט, פעולות הגורמות
                        להפרת זכויות קניין רוחני של ספקי התכנים והטכנולוגיה באתר. </p></li>
                </ol>
                <h4><strong>5. הגבלת אחריות</strong></h4>
                <ol start="1">
                    <li><p>5 BeachCam, עובדיה וכל הבאים מטעמה, לא יישאו בכל אחריות לכל נזק שייגרם על ידי
                        נסיבות מעבר לשליטתה, ובכלל זאת, כוח עליון, אש, מלחמה, פעולות חבלה,
                        הפרעות קשות בסדר הציבורי, מחסור במקורות סבירים להספקה של ציוד חיוני
                        למערכת, מעשה או מחדל של ספק תקשורת אחר או מגבלות שהוטלו על ידו,
                        חוקים, תקנות או צווים ממשלתיים, מגבלות הרשת, מגבלות צבאיות או ביטחוניות,
                        אמצעים למניעת הונאה, גישה לא מורשית של צדדים שלישיים לרבות "האקרים"
                        או נסיבות אחרות שאינן בשליטתה של BeachCam. </p></li>
                    <li><p>5 הנך מצהיר כי ידוע לך כי קבלת השירותים מהאתר ואיכותם תלויה בגורמים
                        טכנולוגיים שאינם תמיד בשליטת BeachCam, כמו זמינות ותקינות של רשת האינטרנט
                        ורשתות תקשורת אחרות וכן ציוד הקצה שברשותך. לפיכך, לא תהיה רשאי
                        להעלות כלפי BeachCam כל טענה או דרישה בכל הנוגע לאובדן, עוגמת נפש, נזק או
                        הפסד בגין כל טעות, תקלה, פגיעה באיכות או כשל בהעברת השירותים, ככל
                        שטעות, תקלה או כשל כאמור נובעים, במישרין או בעקיפין, מתקלה או כשל
                        ברשת האינטרנט או רשת אחרת או ציוד הקצה שברשותך. </p></li>
                    <li><p>5 מבלי לגרוע מהאמור, אחריותה הכוללת של BeachCam ומי מספקיה לשירותים או
                        לנזקים אחרים שנגרמו משימוש באתר ותרופתך הבלעדית במקרה ש BeachCam
                        תימצא אחראית על אף ההגבלות על האחריות שלעיל תהיה מוגבלת לסכום
                        ששילמת בפועל אם בכלל עבור השירותים שהתקבלו באמצעות האתר במהלך
                        12 החודשים שקדמו למועד קרות האירוע שגרם לנזק.
                        האמור בסעיף זה בא להוסיף ואינו גורע מכל חסינות, הגבלה ו/או פטור מאחריות
                        המוענקים ל BeachCam או אשר יוענקו לה בעתיד על-פי הוראות הדין. </p></li>
                </ol>
                <h4><strong>6. שימוש לא חוקי ואסור</strong></h4>
                <ol start="1">
                    <li><p>6 חל איסור להשתמש באתר ובשירותים לכל מטרה לא חוקית או לכל מטרה
                        האסורה בתנאי שימוש אלה. אינך רשאי להשתמש באתר ובשירותים באופן העלול
                        להזיק לאתר, לתכנים באתר, לרשתות תקשורת או לציוד תקשורת כלשהו כולל
                        טלפונים ניידים, לשתקם, לגרום להם עומס יתר או לפגוע בהם, או להפריע
                        לשימוש של גורם אחר כלשהו ולהנאה שלו מהאתר. </p></li>
                    <li><p>6
                        אינך רשאי לנסות לקבל גישה ללא הרשאה לאתר, לשירותים, לתכנים, או
                        למערכות מחשבים או רשתות המחוברות לאתר באמצעות "פריצה" Hacking ,
                        "כריית סיסמאות" Mining Password או בכל אמצעי אחר. אינך רשאי להשיג או
                        לנסות להשיג שירותים או מידע כלשהם באמצעים שלא הועמדו לרשותך באופן
                        מכוון על-ידי BeachCam.
                    </p></li>
                </ol>
                <h4><strong>7. מדיניות פרטיות</strong></h4>
                <ol start="1">
                    <li><p> 7 תקנון זה כפוף למדיניות הפרטיות המופיעה באתר MyBeachCam.</p></li>
                </ol>
                <h4><strong>8. אבטחת מידע</strong></h4>
                <ol start="1">
                    <li><p> 8 התקשורת עם האתר נעשית באמצעות רשת האינטרנט, ולפיכך קיימת סכנה
                        כי מידע יגיע לידי צדדים שלישיים. תכנים שתפרסם באתר עשויים להיות נגישים
                        לכל משתמשי האינטרנט. </p></li>
                    <li><p> 8 איננו בודקים את המידע והתכנים שעוברים ברשת, למעט לצורך הגנה על הרשת
                        שלנו, ולכן באחריותך לנקוט באמצעי אבטחה הולמים כגון כנגד וירוסים, חדירה
                        למחשבים, אובדן מידע, חשיפת סודות וכו', הנובעים מקישור לרשתות תקשורת
                        חיצוניות או מהיכולת לקבל קבצים ומידע אחר ממקורות שונים, וכל שימוש
                        שתעשה במידע או בשירותים הוא על אחריותך בלבד</p></li>
                </ol>

                <Container sx={{ m: "2em auto", width: 80 }}>
                    <img
                        src="https://beachcam.co.il/images/BeachCamIcon.png"
                        alt="BeachCam Israel"
                        width="36px"
                    />
                </Container>
            </article>
        </Container>

    )
}