import React, { useState } from 'react';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { createTheme } from "@mui/material/styles";
import DuoIcon from '@mui/icons-material/Duo';
import moment from 'moment';
import LiveTvIcon from "@mui/icons-material/LiveTv";
import { getStorage, ref, getMetadata } from "firebase/storage";
import { isLightMode} from "../utils/IsLightMode";


const VideoReportComponent = (props) => {

    const [dateUpdated, setDateUpdated] = useState(false)
    const [timeUpdated, setTimeUpdated] = useState(false);
    // Create a reference to the file whose metadata we want to retrieve
    const storage = getStorage();
    const forestRef = ref(storage, 'video/videoForcast');

    // Get metadata properties
    getMetadata(forestRef)
        .then((metadata) => {
            setDateUpdated(formatDate(metadata.updated));
            setTimeUpdated(formatTime(metadata.updated));
            // Metadata now contains the metadata for 'images/forest.jpg'
        })
        .catch((error) => {
            console.log("metadata-error:", error)
            // Uh-oh, an error occurred!
        });

    const lightMode = isLightMode();
    console.log("lightMode:", JSON.parse(lightMode))

    const time = moment().format();
    const formatDate = (time) => moment(time).format('DD/MM/YY');
    const formatTime = (time) => moment(time).format("HH:mm");

    const videoURL = new URL("https://firebasestorage.googleapis.com/v0/b/mbc-dev-4c869.appspot.com/o/video%2FvideoForcast?alt=media" + `&time=${time}`)
    return (
        <Container className="VideoUploader-container text-center" component="main"
            maxWidth="md"
            sx={{ mb: 4, mt: 2, minHeight: "65vh" }}>
            <Card sx={{
                pb: 3, backgroundColor: (theme) =>
                    lightMode
                        ? "#fff"
                        : "#2a2c32",
            }}>
                <CardHeader
                    title={<Typography variant="h6" gutterBottom component="h6" sx={{ bp: 0, mb: 0 }}>
                        דווח מהחוף
                    </Typography>}

                    subheader={
                        <Box sx={{ textAlign: "center", color: (theme) => theme.palette.text.titleHighy }}>
                            <Typography variant="body2" gutterBottom component="div" sx={{ bp: 0, mb: 0 }}>
                                {dateUpdated && timeUpdated && ("עודכן בתאריך: " + dateUpdated + "  בשעה: " + timeUpdated + " ")}
                            </Typography>
                        </Box>
                    }
                    action={<LiveTvIcon />}
                    sx={{
                        backgroundColor: (theme) =>
                            lightMode
                                ? theme.palette.grey[200]
                                : "#282a30",
                        color: (theme) => theme.palette.text.titleHighy,
                    }}
                />

                <CardContent>
                    {videoURL && (
                        <Box className="IframeComponent" sx={{ width: '100%', textAlign: "center" }} >
                            <React.Fragment>
                                <div className="cam-container">
                                    {videoURL && (
                                        <Box >
                                            <video className='video-report' width={"100%"} controls>
                                                <source src={videoURL} type="video/mp4" />
                                            </video>
                                        </Box>
                                    )}
                                    {/* <iframe
                                        className='cam-iframe'
                                        sx={{ xs: { maxHeight: "30vh" } }}
                                        title="cam_1"
                                        id="camA"
                                        src={videoURL}
                                        // src="/videoreportiframe"
                                        width="100%"
                                        height="460"
                                        frameBorder="0"
                                        allowFullScreen
                                    ></iframe> */}
                                </div>
                            </React.Fragment>
                        </Box>

                    )}
                </CardContent>

                <DuoIcon sx={{ color: (theme) => theme.palette.text.titleHighy }} />
                <Typography component="h1" variant="h5" sx={{ pb: 2, color: (theme) => theme.palette.text.titleHighy, fontSize: { xs: 22, md: 30 } }}>

                    My Beach Cam</Typography>
                <Button variant='outlined' sx={{ width: "90%", }} onClick={() => window.location = "/cameras"}>
                    לשידור החי
                </Button>
            </Card>
        </Container>
    );
};

export default VideoReportComponent;
