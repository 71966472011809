export const membershipPackages = [
    {
        title: 'מנוי פרו שנתי 12 חודשים',
        mbcCode: 'pro12',
        info: 'משלמים על 8 חודשים ומקבלים עוד 4 חודשים נוספים חינם!',
        listPrice: 360,
        discountText: 'הנחה 120 ש"ח בתוקף ל 200 הרוכשים הראשונים ',
        totalPrice: 240,
        teaser: '!כולל 4 חודשים - חינם',
        imgSrc: 'https://beachcam.co.il/images/BeachCamIcon.png',
        packageName: 'פרו שנתי',
    },
    {
        title: 'מנוי פרו חצי שנתי',
        mbcCode: 'pro6',
        info: 'משלמים רק על 5 חודשים ומקבלים חודש נוסף חינם!',
        listPrice: 180,
        discountText: 'הנחה של 30 ש"ח בתוקף ל 200 רוכשים בלבד',
        totalPrice: 150,
        teaser: '!כולל חודש - חינם',
        imgSrc: 'https://beachcam.co.il/images/BeachCamIcon.png',
        packageName: 'פרו חצי שנתי',
    },
    {
        title: 'מנוי פרו חודשי - מתחדש',
        mbcCode: 'pro1',
        info: ' משלמים מדי חודש בהוראת קבע',
        listPrice: 30,
        discountText: 'לחודש בתשלום חודשי ללא התחייבות',
        totalPrice: 30,
        teaser: 'ניתן להפסיק מתי שרוצים',
        imgSrc: 'https://beachcam.co.il/images/BeachCamIcon.png',
        packageName: 'פרו חודשי מתחדש',
    },
    
]