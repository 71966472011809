import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const cameraSlice = createSlice({
  name: "camera",
  initialState,
  reducers: {
    setCamera: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCamera } = cameraSlice.actions;

export default cameraSlice.reducer;
