import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    saveUserInfo: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { saveUserInfo } = userInfoSlice.actions;

export default userInfoSlice.reducer;