import * as React from 'react';
import { useEffect } from "react"
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";
import { textfieldStyling } from "../../theme/theme.js";
import { useState, useRef } from 'react';
import { fetchUserByUserEmail } from "../../api/manager.js";
import { getAllUsersList, updatePackageByUid, getUserPackageById, getPackageHistoryByUid, getUsers, getRefByString } from "../../api/db.js";
import { getDateByOption } from "../../utils/DateUtils.js";
import LinearProggresComponent from "../../components/LinearProggresComponent.js";
import DatePickerComponent from "../../components/DatePickerComponent.js";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Alert from '@mui/material/Alert';
import SaveIcon from '@mui/icons-material/Save';
import Divider from '@mui/material/Divider';
import MenuPopupStateComponent from "../../components/MenuPopupStateComponent.js";
import { updateOptions } from "../../data/updateOptions.js";
import InputNumberComponent from "../../components/InputNumberComponent.js";
import { useSelector } from "react-redux";
import moment from 'moment';
import { validateField } from "../../utils/validation.js";
import { Box } from '@mui/system';

const ManagerPage = () => {
  const user = useSelector((state) => state.userInfo.value);
  const [emailInput, setEmailInput] = useState("");
  const [userInfo, setUserInfo] = useState(false);
  const [progress, setProgress] = useState(false);
  const [userPackage, setUserPackage] = useState(false);
  const [updatesOpen, setUpdatesOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [selection, setSelection] = useState(false);
  const [label, setLabel] = useState(false);
  const [selectedNumber, setSelectedNum] = useState(false);
  const [optionSelected, setOptionSelected] = useState(false);
  const [crmText, setCrmText] = useState(false);
  const [saveOpen, setSaveOpen] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [menuComponetLabel, setMenuComponentLanel] = useState("mmm");
  const [packageHistory, setPackageHistory] = useState(false);
  const [alert, setAlert] = useState("");
  const anchorRef = useRef(null);
  const [usersCount, setUsersCount] = useState(false);
  const [validEmailsCount, setValidEmailsCount] = useState(false);
  const [users, setUsers] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    getUsers()
      .then(snapshot => {
        setUsersCount(Object.keys(snapshot.val()).length)
      })
      getRefByString("validEmails")
      .then(snapshot => {
        console.log("validEmails:", snapshot.val() )
        snapshot.val() && setValidEmailsCount(Object.keys(snapshot.val()).length)
      })
      getAllUsersList("users")
      .then(snapshot => {
        setUsers(snapshot.data)
        console.log("getAllUsersList:", snapshot.data )
      })
      .catch(error => {
        console.log("getAllUsersList- error:", error )
      })
  }, []);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const menuCallBack = (selected) => {
    setLabel(updateOptions[selection][selected].name);
    setMenuComponentLanel(updateOptions[selection][selected].name);
    setOptionSelected(updateOptions[selection][selected]);
  }

  const formatDate = date => moment(date).format('DD/MM/YYYY');

  const text = {
    packageName: "סוג מנוי",
    packageEndsDate: "תוספת זמן",
    disablePackage: "הקפאת מנוי",
  }

  const infoText = () => {
    console.log("selection:", selection)
    if (selectedNumber && selection === "packageEndsDate" && label === "עד תאריך") {
      return (text[selection] + " " + label + " " + (selectedNumber ? formatDate(selectedNumber) : "ניבחר") + " ללקוח ") + userInfo.email;
    }
    if (selection === "packageName") {
      return (text[selection] + " " + label + " ללקוח ") + userInfo.email;
    }
    if (selection === "disablePackage") {
      return (text[selection] + " " + label + " " + (selectedNumber ? formatDate(selectedNumber) : "ניבחר") + " ללקוח ") + userInfo.email;
    }
    return (text[selection] + " " + (selectedNumber ? selectedNumber : "") + " " + label + " ללקוח ") + userInfo.email;
  }

  const handleClose = (selection) => {
    if (selection && selection !== "close") {
      console.log("selection:", selection);
      setSelection(selection);
      setLabel(false);
      setCrmText(false);
      setSelectedNum(false);
      setAlert(false);
      setMenuComponentLanel(updateOptions.optionsNames[selection])
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      // anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const resetState = () => {
    setUserInfo(false);
    setUserPackage(false);
    setProgress(false);
    setUpdatesOpen(false);
    setSelection(false);
    setOpen(false);
    setLabel(false);
    setSelectedNum(false);
    setCrmText(false);
    setSaveOpen(false);
    setPackageHistory(false);
    setError(false);
  }

  const cancelUpdate = (e) => {
    e.preventDefault();
    setLabel(false);
    setSelectedNum(false);
    setCrmText(false);
    setSaveOpen(false);
    setLabel(false);
    setOpen(false);
  }

  const clearInputValue = () => {
    resetState();
    setEmailInput("");
  }

  const getUserPackageHistory = async () => {
    const uid = userInfo.uid;
    try {
      const response = await getPackageHistoryByUid(uid);
      if (response) {
        const packageHistory = response.val();
        packageHistory && setPackageHistory(packageHistory);
        return packageHistory;
      }
    } catch (error) {
      console.error("There has been a problem with your operation:", error);
    }
  }

  const getUserInfo = () => {
    setPackageHistory(false);
    setProgress(true);
    !emailInput ? resetState(false) : fetchUserByUserEmail(emailInput)
      .then((res) => {
        res ? setUserInfo(res) : setUserInfo(false);
        res ? setUpdatesOpen(true) : setUpdatesOpen(false);
        !res && setUserPackage(false);
        console.log("getUserInfo", res)
        res && getUserPackageById(res.uid)
          .then(res => {
            if (res.exists()) {
              res ? setUserPackage(res.val().package) : setUserPackage(false);
              res ? setUpdatesOpen(true) : resetState(false);
              setProgress(false);
            }
          })
          .catch((error) => {
            console.log("error", error)
          })
        setProgress(false);
      })
      .catch((error) => {
        console.log("error", error)
      });
  }

  const handler = (event) => {
    const validation =  validateField("email", emailInput);
    !validation.valid? setError(validation.error): setError(false);
    validation.valid && getUserInfo();
    event.preventDefault();
    console.log("email input:", emailInput)
  }

  const postUpdatePackageByUid = (e) => {
    e.preventDefault();
    const update = () => {
      getUserInfo();
      setAlert(false);
    }
    const date = getDateByOption(optionSelected, selectedNumber, userPackage.packegeEndsDate);
    const getObjectToUpdate = () => {
      switch (selection) {
        case "packageName":
          return {
            updateType: selection,
            packageEndsTime: date,
            uid: userInfo.uid,
            updatedBy: `${user.firstName} ${user.lastName}`,
            crmText: infoText() + ", " + crmText,
            packageCode: optionSelected.code,
            packageName: optionSelected.name,
          }
        case "packageEndsDate":
          return {
            updateType: selection,
            packageEndsTime: date,
            uid: userInfo.uid,
            updatedBy: `${user.firstName} ${user.lastName}`,
            crmText: infoText() + ", " + crmText,
            packageCode: userPackage.packageCode,
            packageName: userPackage.packageName
          }
        case "disablePackage":
          return {
            updateType: selection,
            packageEndsTime: date,
            uid: userInfo.uid,
            updatedBy: `${user.firstName} ${user.lastName}`,
            crmText: infoText() + ", " + crmText,
            packageCode: userPackage.packageCode,
            packageName: userPackage.packageName
          }
        default:
          return {
            updateType: selection,
            packageEndsTime: date,
            uid: userInfo.uid,
            updatedBy: `${user.firstName} ${user.lastName}`,
            crmText: infoText() + ", " + crmText,
            packageCode: userPackage.packageCode,
            packageName: userPackage.packageName,
          }
      }
    }
    setAlert(false);
    setUpdating(true);
    console.log("getObjectToUpdate:", getObjectToUpdate());
    updatePackageByUid(
      getObjectToUpdate()
    )
      .then((res => {
        setUpdating(false);
        console.log("res:", res)
        setCrmText(false);
        setSaveOpen(false);
        setAlert("success");
        setTimeout(() => {
          update();
        }, 3000);
      }))
      .catch(error => {
        setUpdating(false);
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.log("error:", code, message, details);
        setCrmText(false);
        setSaveOpen(false);
        setAlert(code);
      })
  }

  return (
    <div className="text-center pad-x-1">
      {console.log("users:", users.list)}
      <div> סה"כ נרשמו: {users && users.list && users.list.length}</div>
      <div dir="rtl"> סה"כ משתמשים:  {usersCount && usersCount}</div>
      <div dir="rtl"> סה"כ כתובות מאושרות:  {validEmailsCount && validEmailsCount}</div>
      <Grid container spacing={2} sx={{ border: 1, borderRadius: 2, bgcolor: "#ff00000d", direction: "rtl", textAlign: "right", width: "100%", p: 1, m: 0, mt: 1 }}>
        <Grid item xs={12} sm={6} md={4} sx={{ pt: 1, m: 1, mt: 0, mb: 0 }}>
          <TextField
            sx={{ ...textfieldStyling }}
            required
            id="email"
            name="email"
            label=" הזנת כתובת אימייל"
            fullWidth
            autoComplete="email"
            variant="standard"
            value={emailInput}
            onChange={(e) => setEmailInput(e.target.value)}
          />
          <Box sx={{color: "#8d0505"}}>{error && error}</Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={(e) => handler(e)}
          >
            חיפוש
          </Button>
          {emailInput &&
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              sx={{ mt: 3, mb: 0 }}
              onClick={() => clearInputValue()}
            >
              נקה
            </Button>}
          {progress && < LinearProggresComponent />}
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ pr: 2, pt: 1, pb: 0 }}>
          <div>שם משתמש: {userInfo && userInfo.displayName}</div>
          <div> אימייל: {userInfo && userInfo.email}</div>
          <div> רישום ראשוני: {userInfo && formatDate(userInfo.metadata.creationTime)}</div>
          <div>מנוי מסוג: {userPackage && userPackage.packageName}</div>
          <div> המנוי החל: {userPackage && formatDate(userPackage.packageCreationDate)}</div>
          <div>המנוי מסתיים: {userPackage && formatDate(userPackage.packegeEndsDate)}</div>
          <div>עודכן ע"י: {userPackage && userPackage.updatedBy}</div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ pr: 1 }}>
          {userInfo && userInfo.uid && !packageHistory && <Button
            type="submit"
            fullWidth
            variant="outlined"
            sx={{ mt: 3 }}
            onClick={() => getUserPackageHistory()}
          >
            הסטוריית משתמש
          </Button>}
        </Grid>
        <Grid item xs={12} sx={{ pr: 2 }}>
          {packageHistory && <Divider sx={{ mb: 1, mt: 0 }} />}
          {packageHistory &&
            <Button
              sx={{ mb: 1, mt: 1 }}
              htmlFor="closeUserHistory"
              size='small'
              onClick={() => setPackageHistory(false)}
              variant='contained'>
              x
            </Button>}
          {packageHistory &&
            Object.keys(packageHistory).slice(0).reverse().map(function (key) {
              return <div
                sx={{ direction: "rtl" }}
                // onClickCapture={() => handeleSelction(options[key].name)}
                // onClick={popupState.close}
                key={packageHistory[key].updatedTime}
              >
                {formatDate(packageHistory[key].updatedTime) + " : "}
                {packageHistory[key].crmText} ...מדווח
                {"  : " + packageHistory[key].updatedBy}
                <Divider sx={{ mb: 1, mt: 1 }} />
              </div>
            })
          }
        </Grid>
      </Grid>
      {updatesOpen &&
        <Grid container spacing={2} sx={{ border: 1, borderRadius: 2, bgcolor: "#ff00000d", direction: "rtl", textAlign: "right", width: "100%", p: 1, m: 0, mt: 1 }}>
          <Grid item xs={12} sm={6} md={4} sx={{ m: 1, mt: 0 }}>
            <div>
              <Button
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {!selection ? " אפשרויות  עדכון" : text[selection]}

              </Button>
              <Popper
                sx={{ zIndex: 1 }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom-start' ? 'left top' : 'left bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={() => handleClose("close")}>
                        <MenuList
                          sx={{ width: "200px", bgcolor: "#1976d2", color: "white" }}
                          autoFocusItem={open}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem onClick={() => handleClose("packageName")}>{text.packageName}</MenuItem>
                          <MenuItem onClick={() => handleClose("packageEndsDate")}>{text.packageEndsDate}</MenuItem>
                          <MenuItem onClick={() => handleClose("disablePackage")}>{text.disablePackage}</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4} sx={{ pr: 2, pt: 1, pb: 3 }}>
            {selection && <MenuPopupStateComponent label={menuComponetLabel} callBack={(selected) => menuCallBack(selected)} selection={selection} updateOptions={updateOptions} />}
          </Grid>
        </Grid>
      }
      {label && <Grid container spacing={2} sx={{ border: 1, borderRadius: 2, bgcolor: "#ff00000d", direction: "rtl", textAlign: "right", width: "100%", p: 1, m: 0, mt: 1 }}>
        <Grid item xs={12} sm={6} md={4} sx={{ pt: 1, m: 1, mt: 0 }}>
          {selection !== "packageName" && (label === "עד תאריך" || label === "החל מתאריך" ? <DatePickerComponent setSelectedNum={setSelectedNum} /> :
            <InputNumberComponent label={label} setSelectedNum={setSelectedNum} />)}
        </Grid>

        <Grid item xs={12} sm={6} md={4} sx={{ pr: 2, pt: 1, pb: 3 }}>
          <span>{infoText()}</span>
        </Grid>
      </Grid>}
      {((label && selectedNumber) || (label && selection === "packageName")) && <Grid container spacing={2} sx={{ border: 1, borderRadius: 2, bgcolor: "#ff00000d", direction: "rtl", textAlign: "right", width: "100%", p: 1, m: 0, mt: 1 }}>
        <Grid item xs={12} sm={6} md={4} sx={{ pt: 1, m: 1, mt: 0 }}>
          <TextField
            id="filled-textarea"
            label="יש לציין תיעוד והסבר לשרות לקוחות"
            placeholder="המשתמש פנה"
            multiline
            variant="outlined"
            fullWidth
            size="small"
            onChange={(e) => setCrmText(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ pr: 2, pt: 1, pb: 3 }}>
          {crmText && !saveOpen && <Button
            // sx={{ display: "inline", mb: 2, mr: 4 }}
            type="submit"
            variant="contained"
            onClick={() => setSaveOpen(true)}
          >
            עדכן
          </Button>}
          {alert && !saveOpen && <Alert
            severity={alert === "success" ? "success" : "error"}
            sx={alert !== "success" ? {
              border: "1px solid red",
              color: "red",
            } : null}
          >{alert === "success" ? "העידכון בוצע בהצלחה" : "התראה - הפעולה לא בוצעה!"}
          </Alert>}

          {crmText && saveOpen &&
            <div>
              <Button
                sx={{ display: "inline", mb: 2, mr: 4 }}
                type="submit"
                variant="outlined"
                onClick={(e) => cancelUpdate(e)}
                color="error"
              >
                ביטול
              </Button>
              <Button
                sx={{ display: "inline-flex", justifyContent: 'space-around', mb: 2, mr: 4 }}
                type="submit"
                variant="contained"
                onClick={(e) => postUpdatePackageByUid(e)}
                color="success"
                startIcon={<SaveIcon sx={{ ml: 3 }} />}
              >
                הפרטים נכונים - עדכן
              </Button>
              {updating && < LinearProggresComponent />}
            </div>
          }
        </Grid>
      </Grid>}
    </div>
  );
}

export default ManagerPage;
