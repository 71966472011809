import * as React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme } from "@mui/material/styles";
import Slide from "@mui/material/Slide";

const theme = createTheme();

export default function AlbumComponent(props) {
  const selectCam = props.selectCam;
  const cam = props.cam;
  const cards = props.cameraList;
  return (
    <div theme={theme}>
      <CssBaseline />
      <Slide direction="up" in={true}>
        <Box
          sx={{
            width: "100%",
            bgcolor: "background.default",
            marginTop: "1rem",
            padding: 0,
            pt: 2
          }}
        >
          {" "}
          <Container sx={{ py: 0, px: 0 }}>
            {/* End hero unit */}
            <Grid container spacing={1}>
              {cards.map((card) => (
                <Grid item key={card.num} xs={6} sm={4} md={4}>
                  <Button size="small">
                    <Card
                      onClick={() => selectCam(card)}
                      sx={{
                        padding: 0,
                        paddingBottom: 0,
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        bgcolor:
                          cam.num === card.num
                            ? "#11569b38"
                            : "background.default",
                      }}
                    >
                      <Typography
                        sx={{ paddingBottom: 0, paddingRight: 1 }}
                        align="right"
                        variant="body"
                        component="h3"
                      >
                        {card ? card.name : null}
                      </Typography>
                      <CardContent sx={{ flexGrow: 1, padding: 0 }}>
                        <CardMedia
                          component="img"
                          sx={{
                            padding: 0,
                            // 16:9
                            // pb: '56.25%',
                          }}
                          image={card.snapShotUrl}
                          alt="random"
                        />
                      </CardContent>
                    </Card>
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      </Slide>
    </div>
  );
}
