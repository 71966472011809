import moment from "moment";

export const getDateByOption = (option, date, packageEndsDate) => {
    console.log("getDateByOption:", option, date, packageEndsDate);
    switch (option.code) {
        case 'customDate':
            console.log("option.code", option.code)
            console.log("customDate date:", moment(date).format())
            return moment(date).format();
        case 'disable_tiilEndsDate':
            return moment(date).format();
        case 'disable_FromStartsDate':
            return moment(date).format();
        case 'disable_FromNow':
            return moment().format();
        default:
            console.log("option.code", option.code)
            console.log("date:", moment(packageEndsDate).add(date, option.code).format())
            return moment(packageEndsDate).add(date, option.code).format();
    }
}