import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../configs/firebaseConfig";
import {
  getDatabase,
  ref,
  set,
  onValue,
  get,
  child,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
} from "firebase/database";
// import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from 'firebase/functions';

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const functions = getFunctions(app);

export const setDeviceIdByUid = (uid, deviceId) => {
  return set(ref(db, `signinDeviceByUid/${uid}`), deviceId)
};

export const setEmailValidByUid = (uid, email) => {
  return set(ref(db, `validEmails/${uid}`), JSON.stringify(email))
};

export const getPaymentUrl = httpsCallable(functions, "getPaymentUrl");
export const updatePackageByUid = httpsCallable(functions, "updatePackageByUid");
export const getAllUsersList = httpsCallable(functions, "getAllUsersList");
// export const updateDeviceIdByUid = httpsCallable(functions ,"updateDeviceIdByUid");
// export const createCustomClaimsByEmail = httpsCallable(functions, "createCustomClaimsByEmail")

export const getUserPackageById = (uid) => {
  return get(ref(db, `usersNew/${uid}`));
};

export const getForcastData = () => {
  return get(ref(db, `forecastData`));
};

export const getPackageHistoryByUid = (uid) => {
  return get(ref(db, `packages/${uid}`));
};

export const getUsers = () => {
  const users = ref(db, `usersNew/`);
  return get(users)
};

// export const setUserData = (uid, userData) => {
//   db.ref("users/" + uid).set({
//       username: userData.userName,
//       email: userData.email,
//       profile_picture: userData.imageUrl,
//     },
//     (error) => {
//       if (error) {
//         // The write failed...
//       } else {
//         // Data saved successfully!
//       }
//     }
//   );
// };

// export const authGetUserByEmail = (auth, email) => {
//   return auth
//     .getUserByEmail(email)
//     .then(function (userRecord) {
//       // See the UserRecord reference doc for the contents of userRecord.
//       console.log("Successfully fetched user data:", userRecord.toJSON());
//     })
//     .catch(function (error) {
//       console.log("Error fetching user data:", error);
//     });
// };

export const getRefByString = (refString) => {
  return get(ref(db, refString));
};

export const dbActivatePackegeListener = (uid, callBack) => {
  const usersNew = ref(db, `usersNew/${uid}`);
  onChildAdded(usersNew, (data) => {
    // console.log("onChildAdded:", data.key, data.val());
    const keyName = data.key;
    keyName === "package" && callBack(keyName, { [keyName]: data.val() })
  });

  onChildChanged(usersNew, (data) => {
    // console.log("onChildChanged:", data.key, data.val())
    const keyName = data.key;
    keyName === "package" && callBack(keyName, { [keyName]: data.val() })
  });

  onChildRemoved(usersNew, (data) => {
    // console.log("onChildRemoved:", data.key, data.val())
    // deleteComment(postElement, data.key);
  });
}

export const dbActivateSigninDeviceIdListener = (uid, callBack) => {
  // console.log("dbActivateSigninDeviceIdListener - call")
  const deviceId = ref(db, `signinDeviceByUid/${uid}`);
  onValue(deviceId, (data) => {
    // console.log("onValue:", data.key, data.val());
    callBack(data.val())
  });

  onChildChanged(deviceId, (data) => {
    // console.log("onChildChanged:", data.key, data.val())
    // callBack( data.val() )
  });

  onChildRemoved(deviceId, (data) => {
    // console.log("onChildRemoved:", data.key, data.val())
  });
}