import { useSelector } from "react-redux";
const IsUserLogin = () => {
  const user = useSelector((state) => state.auth.value);
  if (user || localStorage.getItem("user") !== null) {
    return true;
  } else {
    return false;
  }
};
export default IsUserLogin;
