import React from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from "react-redux";
import {useEffect} from "react"

const PaymentPage3 = () => {
  let url = new URL(document.location);
  let params = url.searchParams;
  let cameraNumber = params.get("camera");
  const matches = useMediaQuery('(min-width:600px)');
  const userInfo = useSelector((state) => state.userInfo.value);

  const iframeDidLoad = (e)=>{
    console.log("iframeLoad", e.target.src)
  }

  
  
  console.log("PaymentUserInfo",userInfo)
  useEffect(() => {
    const handleMessage =(event)=> {
      console.log("messageOrigin:", event.origin);
      if(event.origin === "https://meshulam.co.il"){
        console.log("messageOrigin:", event.origin);
        console.log("messageData.Data:", event.data);
        console.log("messageData.Action:", event.data.action);
        console.log("messageData.status:", event.data.status);
      }
    };
    window.addEventListener('message', (event) => {
      // console.log(`Received message: ${event.data}`);
      handleMessage(event);
  });
    return () => {
        window.removeEventListener("message", handleMessage);
    };
}, []);

  return (
    // <div className=" pad-x-1">
          // transform: matches? 'translate(0px ,-60px) scale(0.7)': null,
      <div className="meshulam-iframe-container">
        <iframe
          onLoad= {(e)=>iframeDidLoad(e)}
          title="paymet1"
          id="paymet1"
          src="https://meshulam.co.il/purchase?b=0df109e6998899670ba421bdda3abf4b"
        //   src="https://meshulam.co.il/quick_payment?b=74c0afb2b3f9561f54cca6c67ec8fb71"
          // src="https://meshulam.co.il/quick_payment?b=a892bb1c07b431e5a0966218518a68bf"
          width="100%"
          height="100%"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
  );
};

export default PaymentPage3;
