import * as React from 'react';
import Box from '@mui/material/Box';
// import { maxHeight } from '@mui/system';

export default function IframeComponent(par) {
    // const goTo = ()=> window.location="/signin";
    const camSrc = par.camSrc;
    if (camSrc) {
        return (
            <Box className="IframeComponent" sx={{ width: '100%', textAlign: "center", paddingTop: 1 }} >
                <React.Fragment>
                <div className="cam-container">
                  <iframe
                    className='cam-iframe'
                    sx={{xs: {maxHeight: "30vh"}}}
                    title="cam_1"
                    id="camA"
                    src={camSrc}
                    width="100%"
                    height="460"
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                </div>
                </React.Fragment>
            </Box>
        );
    }
    return null;
}