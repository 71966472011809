import React, { useState } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
} from "firebase/auth";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { textfieldStyling } from "../../theme/theme.js";
import { validateField, getMessageText } from "../../utils/validation";
import { red } from "@mui/material/colors";
import LinearProggresComponent from "../../components/LinearProggresComponent.js";
import { useSelector, useDispatch } from "react-redux";
import { setProggres } from "../../redux/slice/proggresSlice.js";
import { setDeviceIdByUid } from "../../api/db.js";
import EmailVerificationComponent from "../auth/EmailVerificationComponent.js";
import { v4 as uuidv4 } from 'uuid';
const alertColor = red[500]; // #f44336

export default function SignUp() {
  const proggres = useSelector((state) => state.proggres.value);
  const dispatch = useDispatch();
  let url = new URL(document.location);
  let params = url.searchParams;
  let cameraNumber = params.get("camera");
  const [alertMessage, setAlertMessage] = useState(false);
  const [formErrors, setFormErrors] = useState({ email: null, password: null });
  const [emailVerificationSent, setEmailVerificationSent] = useState(false);
  const auth = getAuth();
  const BasicAlerts = (message) => {
    return (
      <Stack sx={{ width: "100%" }} spacing={2}>
        <Alert severity="error">
          {alertMessage ? alertMessage : null}
        </Alert>
      </Stack>
    );
  };

  const runValidation = (key, val) => {
    const validation = validateField(key, val);
    const { error, valid, fieldName } = validation;
    setAlertMessage((state) => (state ? null : null));
    setFormErrors((formErrors) => ({
      ...formErrors,
      [fieldName]: error,
    }));
    return valid;
  };

  const onFieldChange = (e, label) => {
    if (e && e.target.val && e.target.val.length <= 1) return;
    runValidation(label, e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let userData = {};
    let formValid = true;
    setAlertMessage(false);

    for (let key of data.keys()) {
      let val = data.get([key]);
      const valid = runValidation(key, val);
      if (!valid) {
        formValid = false;
      }
      userData[key] = val;
    }
    //  first creating user in auth
    formValid &&
      dispatch(setProggres(true)) &&
      createUserWithEmailAndPassword(auth, userData.email, userData.password)
        .then((userCredential) => {
          dispatch(setProggres(false));
          const user = userCredential.user;
          console.log("userCredential",userCredential)
          console.log("auth.currentUser:", auth.currentUser)
           // ending email verifiction
           sendEmailVerification(user)
           .then(() => {
             setEmailVerificationSent(true);
             console.log("Email verification sent!")
           });
          // set the device id in db
          const newDeviceId = uuidv4();
          localStorage.setItem("localDeviceId", JSON.stringify(newDeviceId));
          setDeviceIdByUid(user.uid, newDeviceId)
            .then(res => console.log("res:", res))
            .catch(error => console.log("error:", error))
         
          // updating auth
          updateProfile(user, {
            displayName: userData.firstName.trim() + " " + userData.lastName.trim(),
            photoURL: "https://example.com/jane-q-user/profile.jpg",
          })
            .then(function (res) {
              console.log("updateProfile res:", res);
            })
            .catch(function (error) {
              dispatch(setProggres(false))
              console.log("error", error);
            });
        })
        .catch((error) => {
          dispatch(setProggres(false));
          const errorCode = error.code;
          const errorMessage = error.message;
          const errorName = error.name;
          console.log("Error ocured: ", errorCode, errorMessage, errorName);
          setAlertMessage(getMessageText(errorCode));
        });
  };

  return (
    <Container id="signUp" component="main" maxWidth="xs" dir="rtl">
      <CssBaseline />
      {emailVerificationSent ? <EmailVerificationComponent /> :
        <Box
          p={1}
          sx={{
            marginTop: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background: "default",
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: "text.border",
              },
            },
          }}
        >
          <Avatar sx={{ m: 0, bgcolor: "primary.main" }}>
            <img
              src="https://beachcam.co.il/images/BeachCamIcon.png"
              alt="BeachCam Israel"
              width="36px"
            />
          </Avatar>
          <Typography component="h1" variant="h5" sx={{ color: (theme) => theme.palette.text.titleHighy, fontSize: { xs: 22, md: 30 } }}>
            יצירת חשבון BeachCam
          </Typography>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="subtitle1" gutterBottom component="div">
              כאן נרשמים משתמשים חדשים למערכת שלנו{" "}
            </Typography>
          </Box>
          <Box component="form" noValidate onSubmit={handleSubmit} >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="standard"
                  sx={{ ...textfieldStyling }}
                  autoComplete="given-name"
                  name="firstName"
                  fullWidth
                  id="firstName"
                  label="שם פרטי"
                  autoFocus
                  onChange={(e) => onFieldChange(e, "firstName")}
                />
                {formErrors.firstName && (
                  <Box
                    component="div"
                    variant="p"
                    sx={{ color: alertColor, mx: 2 }}
                  >
                    {formErrors.firstName}
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="standard"
                  sx={{ ...textfieldStyling }}
                  fullWidth
                  id="lastName"
                  label="שם משפחה"
                  name="lastName"
                  autoComplete="family-name"
                  onChange={(e) => onFieldChange(e, "lastName")}
                />
                {formErrors.lastName && (
                  <Box
                    component="div"
                    variant="p"
                    sx={{ color: alertColor, mx: 2 }}
                  >
                    {formErrors.lastName}
                  </Box>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  sx={{ ...textfieldStyling }}
                  fullWidth
                  id="email"
                  label="כתובת אימייל"
                  name="email"
                  autoComplete="email"
                  onChange={(e) => onFieldChange(e, "email")}
                />
                {formErrors.email && (
                  <Box
                    component="div"
                    variant="p"
                    sx={{ color: alertColor, mx: 2 }}
                  >
                    {formErrors.email}
                  </Box>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  sx={{ ...textfieldStyling }}
                  fullWidth
                  name="password"
                  label="בחירת סיסמה אישית"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={(e) => onFieldChange(e, "password")}
                />
                {formErrors.password && (
                  <Box
                    id="formErrors"
                    component="div"
                    variant="p"
                    sx={{ color: alertColor, mx: 2 }}
                  >
                    {formErrors.password}
                  </Box>
                )}
              </Grid>
              <Grid id="alertMessage" item xs={12}>
                {alertMessage ? <BasicAlerts sx={{ mt: 8, mb: 4 }} /> : null}
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label={<div>קראתי ואישרתי את <Link href="/termsofuse" variant="body2">
                    תנאי השימוש
                  </Link> באפליקציה.</div>}
                />
              </Grid>
              {proggres && <LinearProggresComponent />}
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 1, mb: 1 }}
            >
              יצירת חשבון
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href={"/cameras?camera=" + cameraNumber} variant="body2">
                  כבר יש לך חשבון? כניסה למנויים
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      }
    </Container>
  );
}
