import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./configs/firebaseConfig";
import { getAnalytics } from "firebase/analytics";
import Reset from "./pages/auth/Reset";
import SignIn from "./pages/auth/SignInComponent";
import SignUp from "./pages/auth/SignUpComponent";
import { PricingComponent } from "./pages/pricing/PricingComponent";
import Home from "./pages/home/Home";
import ManagerPage from "./pages/manager/ManagerPage";
import PaymentPage from "./pages/payment/PaymentPage";
import HelpComponent from "./components/HelpComponent";
import PaymentPage3 from "./pages/payment/PaymentPage3";
import PaymentSuccesPage from "./pages/payment/PaymentSuccesPage";
import PaymentFailPage from "./pages/payment/PaymetFailPage";
import CamerasPage from "./pages/cameras/CamerasPage";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useDispatch } from "react-redux";
import { saveUser } from "./redux/slice/authSlice";
import { setEmailVerified } from "./redux/slice/emailVerification";
import { useEffect, useState } from "react";
import HeaderComponent from "./components/HeaderComponent";
import "./App.css";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { useSelector } from "react-redux";
import Copyright from "./components/CopyrightComponent";
import FooterComponent from "./components/FooterComponent";
import DrawerComponent from "./components/DrawerComponent";
import ContactUsComponent from "./components/ContactUsComponent";
import ProductPage from "./pages/product/ProductPage";
import MyAccountPage from "./pages/myaccount/MyAccountPage";
import { dbActivatePackegeListener, dbActivateSigninDeviceIdListener } from "./api/db";
import { saveUserInfo } from "./redux/slice/userInfoSlice";
import { saveUserPackage, updateUserPackage } from "./redux/slice/userPackageSlice";
import { setDeviceId } from "./redux/slice/deviceIdSlice";
import { setProggres } from "./redux/slice/proggresSlice";
import { themeLight, themeDark } from "./theme/theme";
import { isLightMode } from "./utils/IsLightMode";
import TranzilaPaymentPage from "./pages/payment/Tranzilapayment";
import TranzilaPaymentPage2 from "./pages/payment/Tranzilapayment2";
import VideoReportIframe from "./pages/payment/videoreport";
import LinearProggresComponent from "./components/LinearProggresComponent";
import PrivacyPolicyComponent from "./components/PrivacyPolicyComponent";
import TermsOfUseComponent from "./components/TermsOfUseComponent";
import EmailVerificationComponent from "./pages/auth/EmailVerificationComponent";
import EmailVerificationHandlePage from "./pages/auth/EmailVerificationHandlePage";
import VideoUploader from "./pages/videomanager/VideoManagerPage";
import AlertDevise from "./components/AlertDevice";
import MeshulamPaymentPage from "./pages/payment/MeshulamPaymentPage";
import ReactGA from "react-ga";
import VideoReportComponent from "./components/VideoReportComponent";
const TRACKING_ID = "G-F84M1KGSD6"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);


function App() {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const dispatch = useDispatch();
  const pathName = window.location.pathname;
  const auth = getAuth();
  const user = useSelector((state) => state.auth.value);
  const deviceIdValid = useSelector((state) => state.deviceId.value);
  const emailValid = useSelector((state) => state.emailVerified.value);
  const [manager, setManager] = useState(false);
  const proggres = useSelector((state) => state.proggres.value);
  const [light, setLight] = useState(isLightMode());
  const setTheme = (mode) => {
    setLight(mode);
    localStorage.setItem("mode", JSON.stringify(mode));
  };
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        auth.currentUser.getIdTokenResult()
          .then(function (idTokenResult) {
            const manager = idTokenResult.claims.admin;
            manager && setManager(true);
          });
        dispatch(setProggres(false));
        dispatch(setEmailVerified(auth.currentUser.emailVerified));
        dbActivateSigninDeviceIdListener(user.uid, (deviveId) => {
          if (!localStorage.localDeviceId || JSON.parse(localStorage.localDeviceId) !== deviveId) {
            dispatch(setDeviceId(false));
            dispatch(saveUserInfo(undefined));
            signOut(auth)
              .then(() => {
                localStorage.removeItem("user");
              })
              .catch((error) => {
                console.log("error", error);
              });
          } else {
            dispatch(setDeviceId(true));
          }
        })
        dbActivatePackegeListener(user.uid, (keyName, data) => {
          keyName === "package" && dispatch(updateUserPackage(data));
        });
        dispatch(saveUser(user.uid));
        const {
          metadata,
          email,
          displayName
        } = user;
        const namesAray = displayName && displayName.trim().split(" ");
        const userInfo = {
          createdAt: metadata.createdAt,
          creationTime: metadata.creationTime,
          email,
          firstName: namesAray && namesAray[0],
          lastName: namesAray && namesAray[1],
          type: "trailer"
        };
        dispatch(saveUserInfo(userInfo));
        localStorage.user = JSON.stringify(user.uid);
      } else {
        localStorage.removeItem("user");
        dispatch(saveUser(undefined));
        dispatch(setProggres(false));
      }
    })
    // .catch((error) => {
    //     console.error(error);
    // });;
  }, [auth, dispatch]);

  const getElementConsiderUserStatus = (path) => {
    if (!emailValid && user) {
      switch (path) {
        case "/myaccount":
          return <MyAccountPage />
        case "/signup":
          return <SignUp />;
        case "emailverificationhandelepage":
          return <EmailVerificationHandlePage />
        default:
          return <EmailVerificationComponent />
      }
    }
    if (user && emailValid) {
      switch (path) {
        case "/signin":
          return <CamerasPage />;
        case "/signup":
          return <CamerasPage />;
        case "/cameras":
          return <CamerasPage />
        case "/meshulampaymentpage":
          return <MeshulamPaymentPage />
        case "/myaccount":
          return <MyAccountPage />
        default:
          return <MyAccountPage />;
      }
    }
    if (!user) {
      switch (path) {
        case "/signin":
          return <SignIn />;
        case "/signup":
          return <SignUp />;
        case "/cameras":
          return <SignIn />;
        case "/tranzilapaymentpage2":
          return <Home />
        case "/myaccount":
          return <SignIn />
        default:
          return <SignIn />;
      }
    };
    return <SignIn />
  };

  return (
    <ThemeProvider theme={light ? themeLight : themeDark}>
      <CssBaseline />
      <Router>
        {pathName !== "/paymentsuccespage" &&
          pathName !== "/paymentfailpage" &&
          pathName !== "/videoreportiframe" &&
          <HeaderComponent light={light} setLight={setTheme} />}
        {pathName !== "/paymentsuccespage" &&
          pathName !== "/paymentfailpage" &&
          pathName !== "/videoreportiframe" &&
          <DrawerComponent light={light} setLight={setTheme} />}
        {/* <HeaderComponent light={light} setLight={setTheme} /> */}
        {/* <DrawerComponent light={light} setLight={setTheme} /> */}
        {!deviceIdValid && <AlertDevise message="בחשבון זה בוצעה כניסה ממכשיר אחר" />}
        <Routes>
          {<Route exact path="/signup" element={getElementConsiderUserStatus("/signup")} />}
          <Route exact path="/pricing" element={<PricingComponent />} />
          {<Route exact path="/cameras" element={getElementConsiderUserStatus("/cameras")} />}
          <Route exact path="/" element={<Home />} />
          <Route exact path="/reset" element={<Reset />} />
          <Route exact path="/product" element={<ProductPage />} />
          <Route exact path="/myaccount" element={getElementConsiderUserStatus("/myaccount")} />
          <Route exact path="/cmbo" element={user && manager ? <ManagerPage /> : <Home />} />
          <Route exact path="privacypolicy" element={<PrivacyPolicyComponent />} />
          <Route exact path="termsofuse" element={<TermsOfUseComponent />} />
          <Route exact path="contactus" element={<ContactUsComponent />} />
          <Route exact path="help" element={<HelpComponent />} />
          <Route exact path="/paymentpage" element={<PaymentPage />} />
          <Route exact path="/paymentpage3" element={<PaymentPage3 />} />
          <Route exact path="/paymentsuccespage" element={<PaymentSuccesPage />} />
          <Route exact path="/paymentfailpage" element={<PaymentFailPage />} />
          <Route exact path="/videouploader" element={<VideoUploader />} />
          <Route exact path="/videoforcast" element={<VideoReportComponent />} />
          <Route exact path="/emailverificationhandelepage" element={<EmailVerificationHandlePage />} />
          <Route
            exact
            path="/tranzilapaymentpage"
            element={<TranzilaPaymentPage />}
          />
          <Route exact path="/meshulampaymentpage" element={<MeshulamPaymentPage />} />
          <Route
            exact
            path="/tranzilapaymentpage2"
            element={getElementConsiderUserStatus("/tranzilapaymentpage2")}
          />
          <Route
            exact
            path="/videoreportiframe"
            element={<VideoReportIframe />}
          />

          <Route exact path="/signin" element={getElementConsiderUserStatus("/signin")} />
          {/* <Route path="*" element={<Home />} /> */}
        </Routes>
      </Router>
      {pathName !== "/paymentpage3" &&
        pathName !== "/emailverificationhandelepage" &&
        pathName !== "/paymentpage" &&
        pathName !== "/videoreportiframe" &&
        pathName !== "/tranzilapaymentpage2" &&
        pathName !== "/paymentsuccespage" &&
        pathName !== "/paymentfailpage" && <FooterComponent />}
      {pathName !== "/paymentpage3" &&
        pathName !== "/paymentpage" &&
        pathName !== "/videoreportiframe" &&
        pathName !== "/tranzilapaymentpage2" &&
        pathName !== "/meshulampaymentpage" &&
        pathName !== "/paymentsuccespage" &&
        pathName !== "/paymentfailpage" && <Copyright />}
    </ThemeProvider>
  );
}

export default App;
