import React from "react";
import Avatar from "@mui/material/Avatar";
import SurfingIcon from '@mui/icons-material/Surfing';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const ContactUsComponent = () => {
  let url = new URL(document.location);
  let params = url.searchParams;
  let cameraNumber = params.get("camera");

  return (
    <Box className="contact-us-component text-center pad-x-1">
      <Typography component="h1" variant="p" sx={{ m: 1, color: (theme) => theme.palette.text.titleHighy, fontSize: { xs: 22, md: 30 } }}>
        My Beach Cam
      </Typography>
      <div className="flex-justify-center">
        <Avatar sx={{ mb: 1, bgcolor: "#ff9800" }}>
          <SurfingIcon />
        </Avatar>
      </div>
      <Typography component="p" variant="p" sx={{ mb: 1, fontSize: { xs: 12, sm: 17, md: 22 } }}>
         פניות יתקבלו במייל בלבד 
      </Typography>
      <Typography component="p" variant="p" sx={{ mb: 1, fontSize: { xs: 14, sm: 18, md: 22 } }}>
        support@beachcam.co.il
      </Typography>
      <Typography component="p" variant="p" sx={{ mb: 1, fontSize: { xs: 12, sm: 17, md: 22 } }}>
            יש לציין כתובת המייל איתה נירשמת ופרוט נושא הפנייה  
      </Typography>
      <Typography component="p" variant="p" sx={{ mb: 1, fontSize: { xs: 12, sm: 17, md: 22 } }}>
            יש לציין מספר טלפון ליצירת קשר  
      </Typography>
      
    </Box>
  );
};

export default ContactUsComponent;
