import React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import { useSelector } from "react-redux";
import DrawerListComponent from "./DrawerListComponent";

export default function DrawerComponent(props) {
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [checked, setChecked] = useState(true);
  let light = props.light;
  const userInfo = useSelector((state) => state.userInfo.value);
  const userPackage = useSelector((state) => state.userPackage.value);
  const user = useSelector((state) => state.auth.value);
  let url = new URL(document.location);
  let params = url.searchParams;
  let cameraNumber = params.get("camera");
  // console.log("url", url.pathname);
  const handleChange = (event) => {
    setChecked(event.target.checked);
    props.setLight(!light);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    let target = event.target;
    if (target.tagName === "IMG") {
      target = target.parentNode;
    }
    const name = target.name;
    if (
      name === "themeSwitch" ||
      (event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift"))
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  return (
    <div dir="rtl" className="drawer-container">
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Box className="flex-alignItems-center">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              sx={{
                ml: 0,
                alignSelf: "flex-end",
                display: { xs: "block", sm: "block", md: "none" },
              }}
              onClick={toggleDrawer(anchor, true)}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
            <Box
              sx={{
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                  fontStyle: "normal",
                  fontSize: 15,
                },
              }}
            >
              {userInfo && userInfo.lastName
                ? userInfo.firstName + " " + userInfo.lastName
                : null}
            </Box>
          </Box>
          <div className="mobile-logo">
            {url.pathname !== "/cameras" && !user && (
              <Box
                sx={{
                  mr: 4,
                  display: {
                    xs: "inline",
                    sm: "inline",
                    md: "none",
                    fontStyle: "normal",
                    fontSize: 15,
                  },
                }}
              >
                My BeachCam
              </Box>
            )}
            <IconButton
              sx={{
                ml: 2,
                mt: 0,
                display: { xs: "inline", sm: "inline", md: "none" },
              }}
            >
              <img
                src="https://beachcam.co.il/images/BeachCamIcon.png"
                alt="BeachCam Israel"
                width="36px"
              />
            </IconButton>
          </div>
          <Divider />
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            sx={{
              zIndex: "tooltip",
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                bgcolor: (theme) => theme.palette.background.default,
              },
            }}
          >
            <DrawerListComponent
              userPackage={userPackage && userPackage}
              anchor={anchor}
              toggleDrawer={toggleDrawer}
              url={url}
              cameraNumber={cameraNumber}
              checked={checked}
              handleChange={handleChange}
              light={light}
              user={user}
            />
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
