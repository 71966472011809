import React from "react";
import Avatar from "@mui/material/Avatar";
import AppsIcon from "@mui/icons-material/Apps";
import { useSelector } from "react-redux";

const EmailVerificationComponent = () => {
  const userInfo = useSelector((state) => state.userInfo.value);

  return (
    <div id="EmailVerificationComponent" className="text-center pad-x-1">

      <div className="flex-justify-center">
        <Avatar sx={{ m: 2, bgcolor: "red", }}>
          <AppsIcon sx={{ width: "100%" }} />
        </Avatar >
      </div>


      <h3>שלחנו לך אימייל לאימות</h3>
      <h4>נא להכנס לתיבת המייל </h4>
      {userInfo && userInfo.email && <span>{userInfo.email}</span>}
      <h4>איתה נירשמת וללחוץ על הקישור שלנו לאימות</h4>
      <div className="flex-justify-center">

        <Avatar sx={{ m: 1, bgcolor: "#fff", width: '60px', height: "60px" }}>
          <img
            src="https://beachcam.co.il/images/BeachCamIcon.png"
            alt="BeachCam Israel"
            width="50px"
          />
        </Avatar>
        <h3>My Beach Cam</h3>

      </div>

    </div>
  );
};

export default EmailVerificationComponent;
