export const fetchPackegByUserEmail = async function fetchPackegByEmail(email) {
    try {
        const response = await fetch(`https://us-central1-mbc-dev-4c869.cloudfunctions.net/app/packegeByEmail?email=${email}`);
        if (!response.ok) {
            throw new Error("Network response was not OK");
        }
        const packege = await response.json();
        console.log("packege:", packege)
        return packege;
    } catch (error) {
        console.error("There has been a problem with your fetch operation:", error);
        return false;
    }
};

export const fetchUserByUserEmail = async function fetchUserByEmail(email) {
    try {
        const response = await fetch(`https://us-central1-mbc-dev-4c869.cloudfunctions.net/app/userByEmail?email=${email}`);
        if (!response.ok) {
            throw new Error("Network response was not OK");
        }
        const userInfo = await response.json();
        console.log("userByEmail:", userInfo)
        return userInfo;
    } catch (error) {
        console.error("There has been a problem with your fetch operation:", error);
        return false;
    }
}
