import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

export default function AlertDevise(par) {
    const printText = par.message;
    if (printText) {
        return (
            <Box id="AlertDevise" sx={{ display: "flex", justifyContent: "center" }} >
                <Box sx={{ width: '100%', maxWidth: 360, textAlign: "center", paddingY: 1, border: "1px solid #b00d0d", bgcolor: "#f7d9d9", borderRadius: "4px" }} >
                    <React.Fragment>
                        <Typography
                            variant="outlined"
                            sx={{
                                bgcolor: "#f7d9d9",
                                color: "#b00d0d",
                                paddingX: 3,
                                paddingY: 1,


                            }}
                        // onClick={goTo}
                        >
                            {printText}
                        </Typography>
                    </React.Fragment>
                </Box>
            </Box>
        );
    }
    return null;
}