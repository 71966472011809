import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const emailVerification = createSlice({
  name: "emailVerified",
  initialState,
  reducers: {
    setEmailVerified: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setEmailVerified } = emailVerification.actions;

export default emailVerification.reducer;