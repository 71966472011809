import React, { useState } from 'react';
import { initializeApp } from 'firebase/app';
import 'firebase/storage';
import { firebaseConfig } from "../../configs/firebaseConfig";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DuoIcon from '@mui/icons-material/Duo';
import { useSelector } from "react-redux";
import LinearProgress from '@mui/material/LinearProgress';


// Initialize Firebase
// const firebaseConfig = {
// Add your Firebase config here
// };

const app = initializeApp(firebaseConfig);
// Firebase storage reference
const storage = getStorage(app);

const VideoUploader = () => {
    const [videoFile, setVideoFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadError, setUploadError] = useState(null);
    const [videoURL, setVideoURL] = useState(null);
    const userPackage = useSelector((state) => state.userPackage.value);
    const user = useSelector((state) => state.auth.value);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setVideoFile(file);
    };

    const handleUpload = () => {
        if (videoFile) {
            //   const storageRef = ref(storage, `/video/${videoFile.name}`)
            // const videoRef = ref(storage, `/video/${videoFile.name}`)
            const videoRef = ref(storage, `/video/${"videoForcast"}`)

            const uploadTask = uploadBytesResumable(videoRef, videoFile);

            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setUploadProgress(progress);
                },
                (error) => {
                    setUploadError(error.message);
                },
                () => {
                    // Upload completed successfully, get download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        setVideoURL(downloadURL);
                    });
                }
            );
        }
    };

    return (
        <Container className="VideoUploader-container text-center" component="main"
            maxWidth="md"
            sx={{ mb: 4, mt: 2, minHeight: "65vh" }}>
            <Card>
                <CardHeader
                    title="MyBeachCam"
                    subheader="דווח תחזית וידאו "

                    titleTypographyProps={{ align: "center" }}
                    action={<DuoIcon />}
                    subheaderTypographyProps={{
                        align: "center",
                    }}
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === "light"
                                ? theme.palette.grey[200]
                                : theme.palette.grey[700],
                        color: (theme) => theme.palette.text.titleHighy,
                    }}
                />
                {user && userPackage && userPackage.reporter && <CardContent sx={{
                    backgroundColor: "white",
                    color: (theme) => theme.palette.text.titleHighy,
                }}>
                    {uploadProgress > 0 && uploadProgress < 100 && <LinearProgress/>}
                    {uploadProgress > 0 && <p> טעינה לשרת: {uploadProgress}%</p>}
                    {uploadError && <p>Error: {uploadError}</p>}
                    {videoURL && (
                        <Box >
                            <video width={"100%"} controls>
                                <source src={videoURL} type="video/mp4" />
                            </video>
                        </Box>
                    )}
                    <Box
                        sx={{
                            direction: "rtl",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            mb: 0,
                            p: 0,
                        }}
                    >
                        {<CloudUploadIcon />}
                        <Typography variant="subtitle1" gutterBottom component="div">
                            בחירה או צילום קובץ וידאו קצר
                        </Typography>
                        <Button
                            variant="contained"
                            component="label"
                        >
                            בחר קובץ / צילום וידאו
                            <input
                                type="file"
                                hidden
                                onChange={handleFileChange}

                            />
                        </Button>
                        {videoFile ? <p>{videoFile.name}</p> : <p>לא ניבחר קובץ</p>}
                        {videoFile && <Button sx={{ bgcolor: "green" }} variant="contained" onClick={handleUpload}>שלח</Button>}

                    </Box>
                </CardContent>}
            </Card>

        </Container>
    );
};

export default VideoUploader;
