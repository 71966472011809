import React from "react";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import GlobalStyles from "@mui/material/GlobalStyles";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { signOut, getAuth } from "firebase/auth";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import { saveUser } from "../redux/slice/authSlice";
import { saveUserInfo } from "../redux/slice/userInfoSlice";

import { linkCss } from "../theme/theme";
import useAnalyticsEventTracker from "../utils/UseAnalyticsEventTracker";

const HeaderComponent = (props) => {
  const gaEventTracker = useAnalyticsEventTracker("Contact us");
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(true);
  const userdata = useSelector((state) => state.userInfo.value);
  const pathName = useLocation().pathname;
  const user = useSelector((state) => state.auth.value);
  const auth = getAuth();
  let light = props.light;
  let url = new URL(document.location);
  let params = url.searchParams;
  let cameraNumber = params.get("camera");

  const handleChange = (event) => {
    setChecked(event.target.checked);
    props.setLight(!light);
  };

  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <AppBar
        dir="rtl"
        position="static"
        elevation={0}
        sx={[
          { borderBottom: (theme) => `1px solid ${theme.palette.divider}` },
          { background: (theme) => theme.palette.background2.default },
          { display: { xs: "none", sm: "none", md: "block" } },
        ]}
      >
        <Toolbar sx={{ flexWrap: "wrap" }}>
          <Typography
            variant="h6"
            color="text.primary"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            <Link
              href={"/?camera=" + cameraNumber}
              color="text.primary"
              underline="none"
              sx={{ display: "flex", width: "260px" }}
            >
              <img
                src="https://beachcam.co.il/images/BeachCamIcon.png"
                alt="BeachCam Israel"
                width="36px"
              />
              <Typography
                variant="h6"
                color="text.primary"
                noWrap
                sx={{ flexGrow: 1, mx: 1 }}
              >
                {" "}
                מצלמות חוף ישראל
              </Typography>
            </Link>
          </Typography>
          <Typography
            variant="p"
            color="text.primary"
            noWrap
            sx={{
              flexGrow: 9,
              mx: 1,
              position: "absolute",
              bottom: 0,
              right: "6%",
            }}
          >
            <Box
              sx={{
                display: { fontStyle: "normal", fontSize: 13 },
              }}
            >
              {userdata && userdata.lastName
                ? userdata.firstName + " " + userdata.lastName
                : null}
            </Box>
          </Typography>

          <nav>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    color="default"
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                labelPlacement="start"
                label={
                  <span
                    style={{
                      color: light ? "#000000de" : "#ff9800",
                      fontSize: 15,
                    }}
                  >
                    {light ? "רקע" : "רקע"}
                  </span>
                }
              />
            </FormGroup>
            {/* <Link
              onClick={() => gaEventTracker("header%videoforcast%link%click")}
              disabled={pathName === "/videoforcast"}
              variant="button"
              underline="hover"
              color="text.primary"
              href={"/videoforcast?camera=" + cameraNumber}
              sx={{ ...linkCss, opacity: pathName === "/videoforcast" ? 0.4 : 1 }}
            >
              דווח מהחוף
            </Link> */}
            <Link
              onClick={() => gaEventTracker("header%myaccount%link%click")}
              disabled={pathName === "/myaccount"}
              variant="button"
              underline="hover"
              color="text.primary"
              href={"/myaccount?camera=" + cameraNumber}
              sx={{ ...linkCss, opacity: pathName === "/myaccount" ? 0.4 : 1 }}
            >
              החשבון שלי
            </Link>
            <Link
              onClick={() => gaEventTracker("header%cameras%link%click")}
              disabled={pathName === "/cameras"}
              variant="button"
              underline="hover"
              color="text.primary"
              href={"/cameras?camera=" + cameraNumber}
              sx={{ ...linkCss, opacity: pathName === "/cameras" ? 0.4 : 1 }}
            >
              מצלמות
            </Link>
            <Link
              onClick={() => gaEventTracker("header%pricing%link%click")}
              disabled={pathName === "/pricing"}
              variant="button"
              underline="hover"
              color="text.primary"
              href={"/pricing?camera=" + cameraNumber}
              sx={{ ...linkCss, opacity: pathName === "/pricing" ? 0.4 : 1 }}
            >
              מחירים
            </Link>
            {pathName !== "/signup" &&
              !user &&
              !localStorage.getItem("user") ? (
              <Link
                disabled={pathName === "/signup"}
                variant="button"
                underline="hover"
                color="text.primary"
                href={"/signup?camera=" + cameraNumber}
                sx={{ ...linkCss, opacity: pathName === "/signin" ? 0.4 : 1 }}
              >
                הרשמה
              </Link>
            ) : null}
          </nav>
          {user || localStorage.getItem("user") ? (
            <Button
              variant="outlined"
              sx={{ my: 1, mx: 1.5 }}
              onClick={() => {
                gaEventTracker("header%signOut%button%click");
                dispatch(saveUser(undefined));
                dispatch(saveUserInfo(undefined))
                localStorage.removeItem("user");
                signOut(auth)
                  .then(() => {
                    console.log("user signed out");
                  })
                  .catch((error) => {
                    console.log("error", error);
                  });
              }}
            >
              לצאת מהמערכת
            </Button>
          ) : null}
          {!user &&
            !localStorage.getItem("user") &&
            pathName !== "/signin" &&
            pathName !== "/cameras" ? (
            <Button
              onClick={() => gaEventTracker("header%signIn%button%click")}
              href={"/signin?camera=" + cameraNumber}
              variant="outlined"
              sx={{ my: 1, mx: 1.5 }}
            >
              כניסה למנויים
            </Button>
          ) : null}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default HeaderComponent;
