import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import SellIcon from "@mui/icons-material/Sell";
import Typography from "@mui/material/Typography";

const check_iOS = () =>{
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}
const iosOs = check_iOS();

const bitImageUrl =
  "https://upload.wikimedia.org/wikipedia/he/thumb/d/d6/Bit_logo.svg/2560px-Bit_logo.svg.png";
const paypalImageUrl = "https://1000logos.net/wp-content/uploads/2017/05/PayPal-Logo-1999.png"
const googlePayImageUrl = "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c7/Google_Pay_Logo_%282020%29.svg/2560px-Google_Pay_Logo_%282020%29.svg.png";
const applepayImageUrl = "https://www.logo.wine/a/logo/Apple_Pay/Apple_Pay-White-Dark-Background-Logo.wine.svg"

export default function PaymetMethodComponent(props) {
  const { select } = props;
  return (
    <Box
      className="PaymetMethodComponent-container"
      sx={{
        width: "370px",
        pt: { xs: 0, ms: 0, md: 2 },
        px: { ms: 0, md: 2 },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Container
        // disableGutters
        maxWidth="sm"
        component="main"
        sx={{ pt: 1, pb: 1, px: 2 }}
      >
        <Typography
          variant="h6"
          align="center"
          color="#1976d2"
          component="h6"
        >
          בחר\י אמצעי תשלום
        </Typography>
      </Container>
      <Container sx={{ mx: 2, width: "90%" }}>
        <Button
          id="select-Bit-btn-Bit"
          onClick={() => select("Bit")}
          fullWidth
          variant="outlined"
          sx={{
            height: "42px",
            p: 0,
            my: 2,
            backgroundImage: `url(${bitImageUrl})`,
            backgroundSize: "50px",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundColor: "#69b5c1"
          }}
        >
        </Button>
        <Button
          id="select-creditCard-btn-Credit"
          onClick={() => select("Credit")}
          fullWidth
          variant="contained"
          sx={{ p: 0, my: 2, fontSize: "28px", height: "42px", backgroundColor: "text.main",}}
        >
          {"אשראי"}
        </Button>
        <Button
          id="select-creditCard-btn-Google-Pay"
          onClick={() => select("GooglePay")}
          fullWidth
          variant="outlined"
          sx={{
            p: 1.2, my: 2, fontSize: "18px",
            backgroundImage: `url(${googlePayImageUrl})`,
            backgroundSize: "140px",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            height: 42
          }}
        >
        </Button>

        {iosOs && <Button
          id="select-creditCard-btn-ApllePay"
          onClick={() => select("ApllePay")}
          fullWidth
          variant="contained"
          sx={{
            p: 1.7, my: 2, fontSize: "18px", height: "42px",
            backgroundImage: `url(${applepayImageUrl})`,
            backgroundSize: "90px",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundColor: "#222222",
            ":hover": {
              bgcolor: "#222222f2",
              color: "white"
            }
          }}
        >
        </Button>}
               {/* <Button
          id="select-creditCard-btn-PayPal"
          onClick={() => select("PayPal")}
          fullWidth
          variant="contained"
          sx={{
            p: 1.2, my: 2, fontSize: "18px",
            backgroundImage: `url(${paypalImageUrl})`,
            backgroundSize: "110px",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            height: 42
          }}
        >
        </Button> */}
      </Container>
    </Box>
  );
}
