import React from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from "react-redux";
import { useEffect } from "react"
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

const PaymentSuccesPage = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const url = new URL(document.location);
  console.log("succesPage:", url.pathname)
  console.log("params:", params)
  

  const removeIframe = () => {
    window.parent.location.reload();
    
  }

  const handleClick = (pathNameString) =>{
    window.parent.location = pathNameString;
    
  }

  useEffect(() => {
    window.parent.postMessage({status:1, action: "payment"})
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  })

  return (
    <div id="success_url_address" className="success-page">
      <Container id="success-page-container" component="main" maxWidth="xs" dir="rtl" sx={{ mt: 0 }}>
        <Box
          p={1}
          sx={{
            padding: "0",
            // boxShadow: 2,
            textAlign: "center",
            // marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background: "default",
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: "text.border",
              },
            },
          }}
        >


           
          <Typography component="p" variant="body2" sx={{ mb: 0, color: (theme) => theme.palette.text.titleHighy }}>
            תודה
          </Typography>
          <Typography component="p" variant="body2" sx={{textAlign:"center", m: "auto", width:"100%" , mb: 1, color: (theme) => theme.palette.text.titleHighy, fontSize: 12 }}>
            {params && params.cField2 ? `${params.cField2.trim()}`: ""}
          </Typography>
          <Typography component="p" variant="body2" sx={{ mb: 1, color: (theme) => theme.palette.text.titleHighy, fontSize: { xs: 17, md: 20 } }}>
            {params && params.cField5 ? `רכשת מנוי  ${params.cField5} ` : "רכשת מנוי לאפליקציה"}
          </Typography>
          
          
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <img
              src="https://beachcam.co.il/images/BeachCamIcon.png"
              alt="BeachCam Israel"
              width="36px"
            />
          </Avatar>
          <Typography sx={{}} variant="body2" gutterBottom component="p">
            התשלום בוצע בהצלחה
          </Typography>
          <Typography variant="body2" gutterBottom component="p">
            והמערכת עידכנה את המנוי שלך
          </Typography>
          {/* <Typography component="h1" variant="h6" sx={{ color: (theme) => theme.palette.text.titleHighy, fontSize: { xs: 22, md: 30 } }}>
            My Beach Cam
          </Typography> */}
          <Box component="div" >
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 1, mb: 1 }}
              onClick={() => handleClick("/cameras") }
              size="small"
            >
              בחזרה למצלמות
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 1, mb: 1 }}
              onClick={() =>handleClick("/myaccount") }
              size="small"
            >
              צפייה בחשבון שלי
            </Button>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default PaymentSuccesPage;