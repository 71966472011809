import React from "react";
import { SelectComponent } from "../../components/SelectComponent";
import Avatar from "@mui/material/Avatar";
import AppsIcon from "@mui/icons-material/Apps";
import KitesurfingIcon from "@mui/icons-material/Kitesurfing";
import SurfingOutlinedIcon from "@mui/icons-material/SurfingOutlined";
import { useEffect } from "react";
import SurfingIcon from '@mui/icons-material/Surfing';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { getAuth } from "firebase/auth";

const Home = () => {
  let url = new URL(document.location);
  let params = url.searchParams;
  let cameraNumber = params.get("camera");

  useEffect(() => {
    const auth = getAuth();

    const user = auth.currentUser;
    console.log("homeUser:", user);
    if (user !== null) {
      // The user object has basic properties such as display name, email, etc.
      const displayName = user.displayName;
      const email = user.email;
      const photoURL = user.photoURL;
      const emailVerified = user.emailVerified;

      // The user's ID, unique to the Firebase project. Do NOT use
      // this value to authenticate with your backend server, if
      // you have one. Use User.getToken() instead.
      const uid = user.uid;
      console.log("homeUser:", user);
    }
  }, []);

  return (
    <Box className="home-page text-center pad-x-1">
      <Typography component="h1" variant="p" sx={{ m: 1, color: (theme) => theme.palette.text.titleHighy, fontSize: { xs: 22, md: 30 } }}>
        My Beach Cam
      </Typography>
      <div className="flex-justify-center">
        <Avatar sx={{ mb: 1, bgcolor: "#ff9800" }}>
          <SurfingIcon />
        </Avatar>
      </div>
      <Typography component="p" variant="p" sx={{ fontSize: { xs: 12, sm: 17, md: 22 } }}>
        אפליקציה לצפייה ישירה ללא פירסומות
      </Typography>
      <Typography component="p" variant="p" sx={{ mb: 1, fontSize: { xs: 12, sm: 17, md: 22 } }}>
        שידור חי מצלמות חוף וגלים
      </Typography>
      <SelectComponent cameraNumber={cameraNumber} />
    </Box>
  );
};

export default Home;
