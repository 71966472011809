import React, { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import LinearProggresComponent from "../../components/LinearProggresComponent";
import { membershipPackages } from "../../data/packages";
import Button from "@mui/material/Button";
import ProductInfoComponent from "../../components/ProductInfoComponent";
import PaymentIfarameComponent from "../../components/PaymentIframeComponent";
import SelectPackageComponent from "../../components/SelectPackageComponent";
import PaymetMethodComponent from "../../components/PaymetMethodComponent";
import PhonePaymentComponent from "../../components/PhonePaymentComponent";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import IsUserLogin from "../auth/isUserLogin"
import { getPaymentUrl } from "../../api/db";
import { query } from "firebase/database";

const steps = ["בחירת מנוי", "אמצעי תשלום", "ביצוע תשלום"];

const MeshulamPaymentPage = () => {
  const [activeStep, setActiveStep] = useState(0);
  let url = new URL(document.location);
  let params = url.searchParams;
  let cameraNumber = params.get("camera");
  const matches = useMediaQuery("(min-width:600px)");
  const mbc_uid = useSelector((state) => state.auth.value);
  const userInfo = useSelector((state) => state.userInfo.value);
  const userPackage = useSelector((state) => state.userPackage.value);
  // const proggres = useSelector((state) => state.proggres.value);
  const [proggres, setProggres] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [paymentIframeUrlData, setPaymentIframeUrlData] = useState(null);
  const [paid, setPaid] = useState(false);
  const [userPhone, setUserPhone] = useState(false);
  const [alert, setAlert] = useState(false);
  const product = membershipPackages[0];

  if (!IsUserLogin()) { window.location = "./signin" };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    if (activeStep === 2) window.scrollTo(0, 0);
  };

  const handleBack = () => {
    if (alert) {
      setSelectedMethod(null);
      setAlert(false);
      setUserPhone(false);
      window.scrollTo(0, 0)
      return
    }
    if (selectedMethod) {
      const frame = document.getElementsByTagName('iframe')[0]
      frame && frame.remove();
      setSelectedMethod(false);
      setPaymentIframeUrlData(false);
      setActiveStep(activeStep - 1);
      setAlert(false);
      // setUserPhone(false);
      window.scrollTo(0, 0)
      return
    }
    window.scrollTo(0, 0)
    setProggres(false);
    setActiveStep(activeStep - 1);
    setSelectedPackage(null);
    setUserPhone(false);
    setSelectedMethod(null);
    if (paymentIframeUrlData) { setPaymentIframeUrlData(false) };
  };

  const handlePaid = (selectedPackage) => {
    // window.location = `/paymentsuccespage?cField2=${userInfo.email && userInfo.email}&cField5=${selectedPackage && selectedPackage.packageName}`;
    // const iframe = document.getElementsByTagName('iframe')[0]
    // iframe && iframe.remove();
    setPaid(true);
    setActiveStep(4)
    // setSelectedPackage(null);
    // setSelectedMethod(null);
    // setPaymentIframeUrlData(false);
    
  }

  const getString = () => {
    const email = userInfo && userInfo.email ? userInfo.email : null;
    const str = `success_url_address=https://mbc-dev-4c869.web.app/paymentsuccespage?&
    fail_url_address=https://mbc-dev-4c869.web.app/paymentfailpage?&lang=il&
    nologo=1&sum=1&currency=1&
    cred_type=1&email=${email ? email : null}&
    mbc_uid=${mbc_uid ? mbc_uid : null}&
    mbc_code=pro12&
    response_return_format=qstr`;
    return str;
  };
  const iframeDidLoad = (e) => {
    setProggres(false);
  };

  // const closeIFrame = () => {
  //   console.log("closeIFrame")
  //   setPaymentIframeUrlData(false);
  // }

  useEffect(() => {
    
    const handleMessage = (event) => {
      if (event.origin === "https://meshulam.co.il" ||
        event.origin === "https://sandbox.meshulam.co.il" ||
        event.origin === "https://secure.meshulam.co.il" ||
        event.origin === "https://mybeachcam.app" ||
        event.origin === "http://localhost:3000") {
        switch (event.data.action) {
          case 'close': {
            console.log("meshulamMessage:close")
            const iframe = document.getElementsByTagName('iframe')[0]
            iframe && iframe.remove();
            setSelectedMethod(false);
            setPaymentIframeUrlData(false);
            setActiveStep(1);
            break;
          }
          case 'payment': {
            if (event.data.status == 1) {
              console.log("meshulamMessage:payment")
              handlePaid();
              // success
            }
            break;
          }
          case 'google_payment': {
            if (event.data.status == 1) {
              console.log("meshulamMessage:google_paymentt")
              handlePaid();
              // success
            }
            break;
          }
          case 'failed_to_load_page': {
            console.log("meshulamMessage:failed_to_load_page")
            break;
          }
          default:
            // console.log("Message Exepded:", event)
        }
      }
    };
    window.addEventListener("message", (event) => {
      // console.log(`Received message: ${event.data}`);
      handleMessage(event);
    });
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const select = (packageItem) => {
    window.scrollTo(0, 0)
    setSelectedPackage(packageItem);
    handleNext();
  };

  const setPhoneNum = (num) => {
    setUserPhone(num)
  }

  const selectMethod = (method) => {
    setProggres(true);
    window.scrollTo(0, 0)
    const data = getPaymentData(method);
    setSelectedMethod(method);
    getPaymentUrl(data)
      .then(res => {
        if (res.data.status === 1) {
          setActiveStep(activeStep + 1);
          setPaymentIframeUrlData(res.data.data);
          return
        }
        setProggres(false);
        setAlert("מספר הטלפון אינו תקין")
        console.log("getPaymentUrl-res-err:", res.data.err);

      })
      .catch(error => {
        console.log("getPaymentUrl-error:", error)
      })
  };

  const getPaymentData = (method) => {
    const data = {
      email: userInfo && userInfo.email ? userInfo.email : null,
      // pageCode: method === "Credit"? meshulam.pageCodeCredit: meshulam.pageCodeBit ,
      // userId: meshulam.userId || null,
      // apiKey: meshulam.apiKey || null,
      method: method || null,
      sum: selectedPackage.totalPrice || null,
      // sum: 1,
      // successUrl: "https://mybeachcam.app/paymentsuccespage",
      // cancelUrl: "https://mybeachcam.app/meshulampaymentpage",
      description: ` רכישת  ${selectedPackage.title} באפליקציית ביץ קאם על ידי ${userInfo.email}`,
      paymentNum: 1,
      // maxPaymentNum: 1,
      pageField: { fullName: userInfo.firstName + " " + userInfo.lastName, phone: userPhone, email: `${userInfo.email}` },
      // companyCommission: null,
      saveCardToken: 1,
      uid: mbc_uid || null,
      userEmail: userInfo.email || null,
      mbcCode: selectedPackage.mbcCode,
      packegeEndsDate: userPackage.packegeEndsDate || null,
      packageName: selectedPackage.packageName || null,
    }
    return data
  }

  return (
    <Box className="MeshulamPaymentPage_container" sx={{ width: "100%", overflow: "hidden" }}>
      <Stepper activeStep={activeStep} sx={{ direction: "rtl", maxWidth: 800, m: "0 auto", pt: 3, pb: 2 }}>
        {steps.map((label) => (
          <Step key={label} >
            <StepLabel sx={{ gap: 1 }}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {proggres && <LinearProggresComponent />}
      {/* {!proggres && !userInfo && <SignIn />} */}
      {selectedPackage && (
        <Box
          id="meshulam_container"
          sx={{
            borderRadius: "5px",
            boxShadow: 4,
            border: "1px solid #bdbdbd",
            maxWidth: "1100px",
            minHeight: 460,
            pb: 10,
            bgcolor: "#ffffff",
            display: "flex",
            width: "95vw",
            m: "0 auto",
            padding: " 1em 1em",
            flexDirection: { xs: "column", sm: "column", md: "row" },
            justifyContent: "center",
            alignItems: { xs: "center", sm: "center", md: "start" },
          }}
        >
          <div
            id="meshulam-iframe-container"
            className="meshulam-iframe-container"
          >

            {!userPhone && <PhonePaymentComponent setPhoneNumber={setPhoneNum} />}
            {alert && <Box component="span" variant="p" sx={{ border: 1, maxWidth: "16em", m: "auto", p: 1, borderRadius: 1, backgroundColor: "#ff000012", color: "red", mt: 6, display: "flex", justifyContent: "center" }}>
              {alert}
            </Box>}
            {!alert && !selectedMethod && userPhone && <PaymetMethodComponent select={selectMethod} />}
            {userInfo && selectedMethod && paymentIframeUrlData && (
              <Box
                // height="100%"
                sx={{
                  pt: { ms: 0, md: 2 },
                  px: { ms: 0, md: 2 },
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  height: selectedMethod === "GooglePay" ? 400 : 600
                }}
              >
                <PaymentIfarameComponent
                  sx={{ with: "100%" }}
                  iframeDidLoad={(e) => iframeDidLoad(e)}
                  getString={() => getString()}
                  urlData={paymentIframeUrlData}
                  method={selectedMethod}
                />
              </Box>
            )}
            { <Box className="backBtn-container" sx={{ width: "370px", display: "flex", justifyContent: "center", px: { xs: 4, ms: 5, md: 7 }, m: "0 auto" }}>
              {!paid && activeStep !== 0 && (
                <Button
                  id="backBtn_paymentPage"
                  fullWidth
                  variant="outlined"
                  onClick={handleBack}
                  sx={{ mb: 2, mt: 2, mx: 0, height: 42 }}>
                  חזרה
                </Button>
              )}
            </Box>}
          </div>
          <Box>

          </Box>
          {<ProductInfoComponent {...selectedPackage} />}
        </Box>
      )}
      {!selectedPackage && <SelectPackageComponent select={select} />}
      {/* <Box sx={{backgroundColor: "#070041",padding: "0 8px",  borderRadius: 1,  width:"95vw",maxWidth: "1100px", m: "0 auto"}}>
      <img width={400}  alt= "payment options"src="https://dev.meshulam.co.il/_media/images/web_business/new_cc_with_pci_white.svg"></img>
      </Box> */}
    </Box>
  );
};

export default MeshulamPaymentPage;
