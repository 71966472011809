import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { getForcastData } from "../api/db";
import WavesTwoToneIcon from '@mui/icons-material/WavesTwoTone';
import WindPowerTwoToneIcon from '@mui/icons-material/WindPowerTwoTone';
import ThermostatAutoTwoToneIcon from '@mui/icons-material/ThermostatAutoTwoTone';
import DeviceThermostatTwoToneIcon from '@mui/icons-material/DeviceThermostatTwoTone';
import PoolTwoToneIcon from '@mui/icons-material/PoolTwoTone';
import WavesIcon from '@mui/icons-material/Waves';
import { Box } from "@mui/system";
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import WbTwilightOutlinedIcon from '@mui/icons-material/WbTwilightOutlined';


const ForecastComponent = (params) => {
  const { camForecastCode } = params;
  const [data, setData] = useState(false);

  const getSwellHight = () => {
    if (data.swelHight[4] && data.swelHight[5]) {
      return data.swelHight[4] + " " + data.swelHight[5];
    }
    if (data.swelHight[4]) {
      return data.swelHight[4]
    }
    return "ים שטוח"
  }

  const getDayLightPeriod = () => {
    return data &&
      data.TenDaysText[0].split("זריחה")[1]
        .replace("שקיעה", "")
        .split("🌜")[0]
        .replace("· :", "-")
        .split("-")
  }

  useEffect(() => {
    getForcastData()
      .then(snapshot => {
        const forecastData = snapshot.toJSON()[camForecastCode];
        setData(forecastData)
      })
      .catch(error => {
        console.log("getForcastData- error:", error)
      })
  }, [camForecastCode]);

  return (
    <div className="forcastComponent">
      <Container
        component="div"
        sx={{ fontWeight: 600, boxShadow: 1, borderRadius: 2, px: 2, mb: 1, pb: { xs: 0, sm: 0, md: 1, }, mt: "3px" }}
      >
        {/* {data && <div><ArrowUpwardIcon /></div>} */}
        {/* {!data && <Skeleton variant="rectangular" height={80} />} */}
        {data && <Grid dir="rtl">
          <div className="flex">
            <WavesIcon sx={{ color: "warning.main", fontSize: 17, ml: 1 }} />
            <Typography component="div" variant="span" color="text.primary" sx={{ color: "warning.main", ml: 0, fontSize: { xs: "0.72rem", sm: "0.8rem", md: "0.8rem" } }} gutterBottom>
              {data.swelHight[0] === "פלטה" ? "אין גלים" : " גלים:" + " " + data.swelHight[0] + data.swelHight[1] + " " + data.swelHight[2] + " " + data.swelHight[3]}
            </Typography>
            <WavesTwoToneIcon color="#547593" sx={{ color: "#3abd41", fontSize: 17, mx: 1 }} />
            <Typography component="div" variant="span" color="text.primary" sx={{ color: "#3abd41", ml: 1, fontSize: { xs: "0.72rem", sm: "0.8rem", md: "0.8rem" } }} gutterBottom>
              {data.swelHight[0] === "פלטה" ? "אין גלים" : " סוול:" + " " + data.subtitle[1] + " " + "מחזור" + " " + data.kpi[1] + " שניות"}
            </Typography>
            <PoolTwoToneIcon color="secondary" sx={{ fontSize: 18, ml: 1, mr: { xs: 0, sm: 2 } }} />
            <Typography component="div" variant="div" color="text.primary" sx={{ color: "#9c27b0", fontSize: { xs: "0.72rem", sm: "0.75rem", md: "0.8rem" } }} gutterBottom>
              {"מצב: " + getSwellHight()}
            </Typography>
          </div>
          <div className="flex">
            <WindPowerTwoToneIcon color="success" sx={{ fontSize: 16, ml: 1 }} />
            <Typography component="span" variant="div" sx={{ color: "#2e7d32", fontSize: { xs: "0.75rem", sm: "0.75rem", md: "0.8rem" } }} >
              הרוח: {data.swelHight && data.kpi[0] + " " + 'קמ"ש' + " " + data.subtitle[0].replace("דרום", "דרומית").replace("צפון", "צפונית")}
            </Typography>
            <DeviceThermostatTwoToneIcon color="primary" sx={{ fontSize: 18, ml: 1, mr: 0 }} />
            <Typography component="span" variant="div" color="info.main" sx={{ color: "#0b899e", fontSize: { xs: "0.75rem", sm: "0.75rem", md: "0.8rem" } }} >
              המים:  {data.kpi[2] && data.kpi[2]}
            </Typography>
            <ThermostatAutoTwoToneIcon sx={{ color: "text.primary", fontSize: 18, ml: 1, mr: 2 }} />
            <Typography component="span" variant="div" color="text.primary" sx={{ fontSize: { xs: "0.75rem", sm: "0.75rem", md: "0.8rem" } }} >
              טמפרטורה בחוץ:  {data.kpi[2] && " " + data.kpi[3]}
            </Typography>
          </div>
        </Grid>}
      </Container>
      <Container
        maxWidth="md"
        component="div"
        sx={{ boxShadow: 1, borderRadius: 2, px: 2, pb: 1 }}
      >
        {<Grid dir="rtl">
          <Typography component="h6" variant="div" color="text.primary" sx={{ mb: 0, color: "success.main", fontSize: { xs: "0.7rem", sm: "0.8rem", md: "0.8rem" } }} >
            התחזית להיום
          </Typography>
          <Typography component="div" variant="div" color="text.primary" sx={{ fontSize: { xs: 9, sm: "0.8rem", md: "0.8rem" } }} >
            תחילת היום,  {data.inDayTextForcast && data.inDayTextForcast.morning.replace("דרום", "דרומית").replace("צפון", "צפונית").replace("פלטה", "הים שטוח גלס..")}
          </Typography>
          <Typography component="div" variant="div" color="text.primary" sx={{ fontSize: { xs: 9, sm: "0.8rem", md: "0.8rem" } }} >
            בהמשך היום {data.inDayTextForcast && data.inDayTextForcast.lunch.replace('בצהרים ובערב', '').replace("ים", "הים").replace("דרום", "דרומית").replace("צפון", "צפונית").replace("פלטה", "שטוח").replace("עם רוח", "והרוח").replace(" גל ", " ").replace("גלהים", "הגלים")}
          </Typography>
          <Typography component="div" variant="div" color="text.primary" sx={{ fontSize: { xs: 9, sm: "0.8rem", md: "0.8rem" } }} >
            לקראת ערב  {data.inDayTextForcast && data.inDayTextForcast.evening.replace("ים", "הים צפוי להיות").replace("דרום", "דרומית").replace("צפון", "צפונית").replace("פלטה", "הים יהיה שטוח,").replace(" גל ", " ").replace("גלהים", "הגלים").replace("הגלים צפוי להיות", "צפוי להיות")}
          </Typography>

          <Box sx={{}}>
            <Typography component="span" variant="div" color="text.primary" sx={{ ml: 2, fontSize: { xs: 9, sm: "0.8rem", md: "0.8rem" } }} gutterBottom>
              <WbSunnyIcon sx={{ color: "#f2fc04", fontSize: 17, ml: 1 }} /> זריחה{data && data.TenDaysText && getDayLightPeriod()[0]}
            </Typography>
            <Typography component="span" variant="span" color="text.primary" sx={{ fontSize: { xs: 9, sm: "0.8rem", md: "0.8rem" } }} gutterBottom>
              <WbTwilightOutlinedIcon sx={{ color: "#ed6c02", fontSize: 17, ml: 1 }} /> שקיעה: {data && data.TenDaysText && getDayLightPeriod()[1]}
            </Typography>
          </Box>
        </Grid>}
      </Container>
    </div>
  );
};

export default ForecastComponent;
