import React from "react";
import Avatar from "@mui/material/Avatar";
import SurfingIcon from '@mui/icons-material/Surfing';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from '@mui/material/Container';
import Link from "@mui/material/Link";

const HelpComponent = () => {
    let url = new URL(document.location);
    let params = url.searchParams;
    let cameraNumber = params.get("camera");

    return (
        <Box>
            <Box className="help-component text-center pad-x-1">
                <Typography component="h1" variant="p" sx={{ m: 1, color: (theme) => theme.palette.text.titleHighy, fontSize: { xs: 22, md: 30 } }}>
                    My Beach Cam עזרה
                </Typography>
                <div className="flex-justify-center">
                    <Avatar sx={{ mb: 1, bgcolor: "#ff9800" }}>
                        <SurfingIcon />
                    </Avatar>
                </div>
            </Box>
            <Container sx={{ fontSize: { xs: "0.7rem", sm: "0.8rem", md: "1rem" } }}>
                <article dir="rtl" className="privacy-policy">
                    <h4>דפדפן מומלץ</h4>
                    <li>אנו ממליצים להפעיל את האפליקצייה באחד מהדפדפים הבאים: כרום, ספארי, אדג'</li>
                    <h4>טלויזיות חכמות ומכשירי אנדרואיד TV</h4>
                    <li>ניתן להפעיל את האפליקצייה במכשירים אלו בקלות ע"י פתיחתה בתוך הדפדפן המותקן במכשירים אלו</li>
                    <h4><strong>מסך הבית (home page)</strong></h4>
                    <li>מומלץ לשמור למסך הבית - כדי לפתוח האפליקצייה מיידית במסך מלא, ללא שורת הכתובת  </li>
                    <h4><strong>שכחתי סיסמה</strong></h4>
                    <li>ניתן לבקש לאפס את הסיסמה בקישור   <Link href="/reset" variant="body2">
                        איפוס סיסמה
                    </Link></li>
                    <Container sx={{ m: "2em auto", width: 80 }}>
                        <img
                            src="https://beachcam.co.il/images/BeachCamIcon.png"
                            alt="BeachCam Israel"
                            width="36px"
                        />
                    </Container>
                </article>
            </Container>
        </Box>
    );
};

export default HelpComponent;
